import {
  Application,
  Connector,
  Organisation,
  Resource,
  ResourceMember,
  ResourceTypeEnum,
  ResourcesService,
  ExtraProcess,
} from '@agilicus/angular';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { AppState, NotificationService } from '@app/core';
import {
  ActionApiApplicationsInitApplications,
  ActionApiApplicationsResetLauncherModel,
  ActionApiApplicationsSubmittingLauncherModel,
} from '@app/core/api-applications/api-applications.actions';
import { ApplicationModelStatus } from '@app/core/api-applications/api-applications.models';
import {
  selectApiApplicationsLauncherModel,
  selectApiApplicationsLauncherModelStatus,
  selectApiApplicationsList,
} from '@app/core/api-applications/api-applications.selectors';
import { getResouces } from '@app/core/api/resources/resources-api-utils';
import {
  getForkThenAttachTooltipText,
  getLauncherApplicationsTooltipText,
  getLauncherCommandArgumentsTooltipText,
  getLauncherCommandPathTooltipText,
  getLauncherDescriptiveText,
  getLauncherDoInterceptDescriptiveText,
  getLauncherDoInterceptTooltipText,
  getLauncherEndExistingIfRunningDescriptiveText,
  getLauncherEndExistingIfRunningTooltipText,
  getLauncherExtraProcessAttachIfAlreadyRunningTooltipText,
  getLauncherExtraProcessCommandArgumentsTooltipText,
  getLauncherExtraProcessExitWhenEndingTooltipText,
  getLauncherExtraProcessForkThenAttachTooltipText,
  getLauncherExtraProcessNameRegexFlagTooltipText,
  getLauncherExtraProcessProgramNameTooltipText,
  getLauncherExtraProcessStartIfNotRunningTooltipText,
  getLauncherExtraProcessStartInTooltipText,
  getLauncherExtraProcessWaitForExitTooltipText,
  getLauncherForkThenAttachDescriptiveText,
  getLauncherHideConsoleDescriptiveText,
  getLauncherHideConsoleTooltipText,
  getLauncherNameTooltipText,
  getLauncherProductGuideLink,
  getLauncherResourceMembersTooltipText,
  getLauncherRunAsAdminDescriptiveText,
  getLauncherRunAsAdminTooltipText,
  getLauncherStartInTooltipText,
} from '@app/core/launcher-state/launcher-utils';
import { initLaunchers } from '@app/core/launcher-state/launcher.actions';
import { LauncherModel } from '@app/core/models/launcher/launcher-model';
import { selectCurrentOrganisation } from '@app/core/organisations/organisations.selectors';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { selectCanAdminIssuers } from '@app/core/user/permissions/issuers.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { catchError, combineLatest, concatMap, Observable, of, Subject, takeUntil } from 'rxjs';
import { delayStepperAdvanceOnSuccessfulApply, handleStateOnFirstStepSelection } from '../application-template-utils';
import { ChiplistInput } from '../custom-chiplist-input/chiplist-input';
import { createChiplistInput } from '../custom-chiplist-input/custom-chiplist-input.utils';
import { FilterChipOptions } from '../filter-chip-options';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { ExtraProcessElement } from '../launcher-overview/launcher-overview.component';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { ResourceType } from '../resource-type.enum';
import { getDefaultResourceMembersColumn, getResourceNameAndTypeString } from '../resource-utils';
import { StepperType } from '../stepper-type.enum';
import { getDefaultNewRowProperties } from '../table-layout-utils';
import { capitalizeFirstLetter, clearFormArray, getEmptyStringIfUnset, modifyDataOnFormBlur, pluralizeString } from '../utils';

@Component({
  selector: 'portal-launcher-stepper',
  templateUrl: './launcher-stepper.component.html',
  styleUrls: ['./launcher-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LauncherStepperComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public currentModel: LauncherModel;
  private modelStatus: ApplicationModelStatus;
  public hasAppsPermissions: boolean;
  public hasUsersPermissions: boolean;
  public hasIssuersPermissions: boolean;
  public connectors: Array<Connector>;
  public connectorFormGroup: FormGroup;
  public nameFormGroup: FormGroup;
  public resourceMembersFormGroup: FormGroup;
  public optionalConfigFormGroup: FormGroup;
  public currentOrg: Organisation;
  public appModelSubmissionProgressBarController: ProgressBarController = new ProgressBarController();
  public stepperType = StepperType.launcher;
  public hasTemplates = false;
  // Change this if we implement the permissions stuff here:
  public completedStepperText = `Your launcher setup is complete (you may need to assign user permissions). Please review it below. Make any corrections needed above.`;
  public pageDescriptiveText = getLauncherDescriptiveText();
  public productGuideLink = getLauncherProductGuideLink();
  public orgId: string;
  public optionalConfigOptions: Array<{ name: string; value: boolean }> = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ];

  public capitalizeFirstLetter = capitalizeFirstLetter;
  public pluralizeString = pluralizeString;

  public getLauncherNameTooltipText = getLauncherNameTooltipText;
  public getLauncherCommandPathTooltipText = getLauncherCommandPathTooltipText;
  public getLauncherCommandArgumentsTooltipText = getLauncherCommandArgumentsTooltipText;
  public getLauncherStartInTooltipText = getLauncherStartInTooltipText;
  public getLauncherDoInterceptTooltipText = getLauncherDoInterceptTooltipText;
  public getLauncherHideConsoleTooltipText = getLauncherHideConsoleTooltipText;
  public getLauncherResourceMembersTooltipText = getLauncherResourceMembersTooltipText;
  public getLauncherApplicationsTooltipText = getLauncherApplicationsTooltipText;
  public getLauncherRunAsAdminTooltipText = getLauncherRunAsAdminTooltipText;
  public getForkThenAttachTooltipText = getForkThenAttachTooltipText;
  public getLauncherDoInterceptDescriptiveText = getLauncherDoInterceptDescriptiveText;
  public getLauncherHideConsoleDescriptiveText = getLauncherHideConsoleDescriptiveText;
  public getLauncherRunAsAdminDescriptiveText = getLauncherRunAsAdminDescriptiveText;
  public getLauncherEndExistingIfRunningDescriptiveText = getLauncherEndExistingIfRunningDescriptiveText;
  public getLauncherForkThenAttachDescriptiveText = getLauncherForkThenAttachDescriptiveText;
  public getLauncherExtraProcessProgramNameTooltipText = getLauncherExtraProcessProgramNameTooltipText;
  public getLauncherExtraProcessCommandArgumentsTooltipText = getLauncherExtraProcessCommandArgumentsTooltipText;
  public getLauncherExtraProcessStartInTooltipText = getLauncherExtraProcessStartInTooltipText;
  public getLauncherExtraProcessNameRegexFlagTooltipText = getLauncherExtraProcessNameRegexFlagTooltipText;
  public getLauncherExtraProcessStartIfNotRunningTooltipText = getLauncherExtraProcessStartIfNotRunningTooltipText;
  public getLauncherExtraProcessExitWhenEndingTooltipText = getLauncherExtraProcessExitWhenEndingTooltipText;
  public getLauncherExtraProcessAttachIfAlreadyRunningTooltipText = getLauncherExtraProcessAttachIfAlreadyRunningTooltipText;
  public getLauncherExtraProcessForkThenAttachTooltipText = getLauncherExtraProcessForkThenAttachTooltipText;
  public getLauncherEndExistingIfRunningTooltipText = getLauncherEndExistingIfRunningTooltipText;
  public getLauncherExtraProcessWaitForExitTooltipText = getLauncherExtraProcessWaitForExitTooltipText;

  public stepperState: { optionalConfig: boolean | undefined; extraProcesses: boolean | undefined } = {
    optionalConfig: undefined,
    extraProcesses: undefined,
  };
  private resourceIdToResourceMap: Map<string, Resource> = new Map();
  private resourceNameAndTypeToResourceMap: Map<string, Resource> = new Map();
  private allResources: Array<Resource>;
  private resourceMembersList: Array<ResourceMember> = [];
  public resourceMembersChiplistInput: ChiplistInput<object>;
  private applicationIdToApplicationMap: Map<string, Application> = new Map();
  private applicationNameToApplicationIdMap: Map<string, string> = new Map();
  public applicationsChiplistInput: ChiplistInput<object>;
  private applicationNamesList: Array<string> = [];
  public filterChipOptions: FilterChipOptions = {
    visible: true,
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA],
  };
  public resourceType = ResourceType.launcher;
  public extraProcessElementsList: Array<ExtraProcessElement> = [];
  // TODO: change this when we add policies that apply to launchers:
  public showPolicies = false;

  public keyTabManager: KeyTabManager = new KeyTabManager();

  @ViewChild('launcherStepper') public stepper: MatStepper;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private customValidatorsService: CustomValidatorsService,
    private resourcesService: ResourcesService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.resetModel();
    this.store.dispatch(initLaunchers({ force: true, blankSlate: false }));
    this.store.dispatch(new ActionApiApplicationsInitApplications(true, false, false));
    const hasUsersPermissions$ = this.store.pipe(select(selectCanAdminUsers));
    const hasAppsPermissions$ = this.store.pipe(select(selectCanAdminApps));
    const hasIssuersPermissions$ = this.store.pipe(select(selectCanAdminIssuers));
    const currentOrg$ = this.store.pipe(select(selectCurrentOrganisation));
    const modelState$ = this.store.pipe(select(selectApiApplicationsLauncherModel));
    const modelStatusState$ = this.store.pipe(select(selectApiApplicationsLauncherModelStatus));
    const applicationsListState$ = this.store.pipe(select(selectApiApplicationsList));
    combineLatest([
      hasUsersPermissions$,
      hasAppsPermissions$,
      hasIssuersPermissions$,
      currentOrg$,
      modelState$,
      modelStatusState$,
      applicationsListState$,
    ])
      .pipe(
        concatMap(
          ([
            hasUsersPermissionsResp,
            hasAppsPermissionsResp,
            hasIssuersPermissionsResp,
            currentOrgResp,
            modelStateResp,
            modelStatusStateResp,
            applicationsListStateResp,
          ]) => {
            let allResources$: Observable<Array<Resource> | undefined> = of(undefined);
            if (!!this.allResources && this.allResources.length !== 0) {
              allResources$ = of(this.allResources);
            } else if (
              !!hasAppsPermissionsResp?.orgId &&
              !!hasAppsPermissionsResp.hasPermission &&
              !!hasUsersPermissionsResp.hasPermission &&
              !!hasIssuersPermissionsResp.hasPermission
            ) {
              allResources$ = getResouces(this.resourcesService, hasAppsPermissionsResp?.orgId, undefined, [
                ResourceTypeEnum.service_forwarder,
              ]).pipe(
                catchError((_) => {
                  this.notificationService.error('Failed to list resource groups');
                  return of(undefined);
                })
              );
            }
            return combineLatest([
              of(hasUsersPermissionsResp),
              of(hasAppsPermissionsResp),
              of(hasIssuersPermissionsResp),
              of(currentOrgResp),
              of(modelStateResp),
              of(modelStatusStateResp),
              of(applicationsListStateResp),
              allResources$,
            ]);
          }
        )
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          hasUsersPermissionsResp,
          hasAppsPermissionsResp,
          hasIssuersPermissionsResp,
          currentOrgResp,
          modelStateResp,
          modelStatusStateResp,
          applicationsListStateResp,
          allResourcesResp,
        ]: [
          OrgQualifiedPermission,
          OrgQualifiedPermission,
          OrgQualifiedPermission,
          Organisation,
          LauncherModel,
          ApplicationModelStatus,
          Array<Application>,
          Array<Resource>
        ]) => {
          this.orgId = hasUsersPermissionsResp?.orgId;
          this.hasAppsPermissions = hasAppsPermissionsResp.hasPermission;
          this.hasUsersPermissions = hasUsersPermissionsResp.hasPermission;
          this.hasIssuersPermissions = hasIssuersPermissionsResp.hasPermission;
          if (!modelStateResp || !modelStatusStateResp || !applicationsListStateResp || !allResourcesResp) {
            return;
          }
          this.modelStatus = modelStatusStateResp;
          if (!this.currentModel || this.modelStatus.complete) {
            this.currentModel = cloneDeep(modelStateResp);
          }
          this.applicationNamesList = applicationsListStateResp.map((app) => app.name);
          this.setResourceMapsAndData(allResourcesResp);
          this.setApplicationMaps(applicationsListStateResp);
          this.currentOrg = currentOrgResp;
          if (!this.modelStatus.saving) {
            this.initializeFormGroups();
            this.resourceMembersChiplistInput = this.getResourceMembersChiplistInput();
            this.applicationsChiplistInput = this.getApplicationsChiplistInput();
          }
          delayStepperAdvanceOnSuccessfulApply(this.stepper, this.modelStatus);
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private resetModel(): void {
    this.store.dispatch(new ActionApiApplicationsResetLauncherModel());
  }

  public onStepperSelectionChange(selectedIndex: number): void {
    handleStateOnFirstStepSelection(selectedIndex, this.modelStatus, undefined, this.resetModel.bind(this), undefined);
  }

  private initializeFormGroups(): void {
    const model = this.getModel();
    this.initializeNameFormGroup(model);
    this.initializeResourceMembersFormGroup();
    this.initializeOptionalConfigFormGroup(model);
    this.changeDetector.detectChanges();
  }

  public getModel(): LauncherModel | undefined {
    return this.currentModel;
  }

  private initializeNameFormGroup(model: LauncherModel): void {
    this.nameFormGroup = this.formBuilder.group({
      name: [
        getEmptyStringIfUnset(model?.name),
        [Validators.required, this.customValidatorsService.launcherNameValidator()],
        [this.customValidatorsService.isDuplicateResourceName(this.orgId, this.modelStatus.complete)],
      ],
      command_path: [getEmptyStringIfUnset(model?.config?.command_path), [Validators.required]],
    });
  }

  private initializeResourceMembersFormGroup(): void {
    this.resourceMembersFormGroup = this.formBuilder.group({
      // This is the input used to enter chip values, not the chip values themselves:
      resource_input_value: '',
    });
  }

  private initializeOptionalConfigFormGroup(model: LauncherModel): void {
    this.optionalConfigFormGroup = this.formBuilder.group({
      optional_config: [this.stepperState.optionalConfig, [Validators.required]],
      command_arguments: getEmptyStringIfUnset(model?.config?.command_arguments),
      start_in: getEmptyStringIfUnset(model?.config?.start_in),
      do_intercept: model?.config.do_intercept === undefined ? null : model?.config.do_intercept,
      hide_console: model?.config.hide_console === undefined ? null : model?.config.hide_console,
      run_as_admin: model?.config.run_as_admin === undefined ? null : model?.config.run_as_admin,
      end_existing_if_running: model?.config.end_existing_if_running === undefined ? null : model?.config.end_existing_if_running,
      fork_then_attach:
        model?.config.interceptor_config.fork_then_attach === undefined ? null : model?.config.interceptor_config.fork_then_attach,
      // This is the input used to enter chip values, not the chip values themselves:
      application_input_value: '',
      extra_processes: [this.stepperState.extraProcesses, [Validators.required]],
      processes_list: this.formBuilder.array([]),
    });
  }

  public getExtraProcessesFormValue(): boolean {
    return this.optionalConfigFormGroup.get('extra_processes').value;
  }

  public getProcessesListFormArray(): UntypedFormArray {
    return this.optionalConfigFormGroup.get('processes_list') as UntypedFormArray;
  }

  public newProcess(): UntypedFormGroup {
    const processFormGroup = this.formBuilder.group({
      program_name: '',
      name_regex_flag: false,
      start_if_not_running: false,
      exit_when_ending: false,
      attach_if_already_running: false,
      fork_then_attach: false,
      wait_for_exit: false,
      command_arguments: undefined,
      start_in: undefined,
    });
    return processFormGroup;
  }

  public addProcess() {
    this.getProcessesListFormArray().push(this.newProcess());
    this.extraProcessElementsList.push(this.makeEmptyExtraProcessElement());
  }

  public removeProcess(index: number) {
    if (index >= 0) {
      this.getProcessesListFormArray().removeAt(index);
      this.extraProcessElementsList.splice(index, 1);
    }
  }

  private makeEmptyExtraProcessElement(): ExtraProcessElement {
    return {
      ...getDefaultNewRowProperties(),
      program_name: '',
      name_regex_flag: false,
      start_if_not_running: false,
      exit_when_ending: false,
      attach_if_already_running: false,
      fork_then_attach: false,
      wait_for_exit: false,
      command_arguments: undefined,
      start_in: undefined,
    };
  }

  public getLauncherApplicationsListValue(): Array<string> {
    return this.optionalConfigFormGroup.get('applications').value;
  }

  public hasAllPermissions(): boolean {
    return !!this.hasAppsPermissions && !!this.hasUsersPermissions && !!this.hasIssuersPermissions;
  }

  public showNoPermissionsText(): boolean {
    if (this.hasAppsPermissions === undefined || this.hasUsersPermissions === undefined || this.hasIssuersPermissions === undefined) {
      return false;
    }
    if (this.hasAllPermissions()) {
      return false;
    }
    return true;
  }

  public onFormBlur<T extends object>(form: FormGroup, formField: string, obj: T): void {
    modifyDataOnFormBlur(form, formField, this.modifyStepperDataOnFormBlur.bind(this), obj);
  }

  private modifyStepperDataOnFormBlur(): void {
    this.updateModel();
  }

  private updateModel(): void {
    this.currentModel.name = this.nameFormGroup.get('name').value;
    this.currentModel.config.command_path = this.nameFormGroup.get('command_path').value;
    this.currentModel.config.command_arguments = this.optionalConfigFormGroup.get('command_arguments').value;
    this.currentModel.config.start_in = this.optionalConfigFormGroup.get('start_in').value;
    this.currentModel.config.do_intercept = this.optionalConfigFormGroup.get('do_intercept').value;
    this.currentModel.config.hide_console = this.optionalConfigFormGroup.get('hide_console').value;
    this.currentModel.config.run_as_admin = this.optionalConfigFormGroup.get('run_as_admin').value;
    this.currentModel.config.end_existing_if_running = this.optionalConfigFormGroup.get('end_existing_if_running').value;
    this.currentModel.config.interceptor_config.fork_then_attach = this.optionalConfigFormGroup.get('fork_then_attach').value;
  }

  public onOptionalConfigChange(isSelected: boolean): void {
    this.stepperState.optionalConfig = isSelected;
    if (!isSelected) {
      this.currentModel.config.command_arguments = '';
      this.currentModel.config.start_in = '';
      this.currentModel.config.do_intercept = false;
      this.currentModel.config.hide_console = false;
      this.currentModel.config.run_as_admin = false;
      this.currentModel.config.end_existing_if_running = false;
      this.currentModel.config.interceptor_config.fork_then_attach = false;
      this.currentModel.applications = [];
      this.optionalConfigFormGroup.get('command_arguments').setValue('');
      this.optionalConfigFormGroup.get('start_in').setValue('');
      this.optionalConfigFormGroup.get('do_intercept').setValue(false);
      this.optionalConfigFormGroup.get('hide_console').setValue(false);
      this.optionalConfigFormGroup.get('run_as_admin').setValue(false);
      this.optionalConfigFormGroup.get('end_existing_if_running').setValue(false);
      this.optionalConfigFormGroup.get('fork_then_attach').setValue(false);
    }
  }

  public onExtraProcessChange(isSelected: boolean): void {
    this.stepperState.extraProcesses = isSelected;
    if (isSelected && this.getProcessesListFormArray().length === 0) {
      this.addProcess();
    }
    if (!isSelected) {
      clearFormArray(this.getProcessesListFormArray());
      this.extraProcessElementsList.length = 0;
    }
  }

  public onCheckboxChange(param: string, isBoxChecked: boolean): void {
    if (param === 'fork_then_attach') {
      this.currentModel.config.interceptor_config.fork_then_attach = isBoxChecked;
      return;
    }
    this.currentModel.config[param] = isBoxChecked;
  }

  public isStepperComplete(): boolean {
    return this.modelStatus.complete;
  }

  private getExtraProcessFromExtraProcessElement(element: ExtraProcessElement): ExtraProcess {
    const result: ExtraProcess = {
      program_name: element.program_name,
      name_regex_flag: element.name_regex_flag,
      start_if_not_running: element.start_if_not_running,
      exit_when_ending: element.exit_when_ending,
      attach_if_already_running: element.attach_if_already_running,
      fork_then_attach: element.fork_then_attach,
      wait_for_exit: element.wait_for_exit,
    };
    if (!!element.command_arguments) {
      result.command_arguments = element.command_arguments;
    }
    if (!!element.start_in) {
      result.start_in = element.start_in;
    }
    return result;
  }

  private updateExtraProcessElementsListFromForm(): void {
    for (let i = 0; i < this.extraProcessElementsList.length; i++) {
      const element = this.extraProcessElementsList[i];
      element.program_name = this.getProcessesListFormArray().controls[i].get('program_name').value;
      element.name_regex_flag = this.getProcessesListFormArray().controls[i].get('name_regex_flag').value;
      element.start_if_not_running = this.getProcessesListFormArray().controls[i].get('start_if_not_running').value;
      element.exit_when_ending = this.getProcessesListFormArray().controls[i].get('exit_when_ending').value;
      element.attach_if_already_running = this.getProcessesListFormArray().controls[i].get('attach_if_already_running').value;
      element.fork_then_attach = this.getProcessesListFormArray().controls[i].get('fork_then_attach').value;
      element.wait_for_exit = this.getProcessesListFormArray().controls[i].get('wait_for_exit').value;
      element.command_arguments = this.getProcessesListFormArray().controls[i].get('command_arguments').value;
      element.start_in = this.getProcessesListFormArray().controls[i].get('start_in').value;
    }
  }

  public submitModel(model: LauncherModel): void {
    if (!!this.optionalConfigFormGroup.get('extra_processes').value && this.extraProcessElementsList.length !== 0) {
      this.updateExtraProcessElementsListFromForm();
      // Add extra processes to the launcher:
      model.config.extra_processes = this.extraProcessElementsList.map((processElement) =>
        this.getExtraProcessFromExtraProcessElement(processElement)
      );
    }
    this.store.dispatch(new ActionApiApplicationsSubmittingLauncherModel(model));
  }

  private setResourceMapsAndData(resourceList: Array<Resource>): void {
    if (!resourceList) {
      return;
    }
    this.allResources = resourceList;
    this.resourceIdToResourceMap.clear();
    this.resourceNameAndTypeToResourceMap.clear();
    this.resourceMembersList = [];
    for (const resource of resourceList) {
      this.resourceIdToResourceMap.set(resource.metadata.id, resource);
      this.resourceNameAndTypeToResourceMap.set(getResourceNameAndTypeString(resource.spec.name, resource.spec.resource_type), resource);
      this.resourceMembersList.push({ id: resource.metadata.id });
    }
  }

  private setApplicationMaps(applicationsList: Array<Application>): void {
    this.applicationIdToApplicationMap.clear();
    this.applicationNameToApplicationIdMap.clear();
    for (const application of applicationsList) {
      this.applicationIdToApplicationMap.set(application.id, application);
      this.applicationNameToApplicationIdMap.set(application.name, application.id);
    }
  }

  private getApplicationsChiplistInput(): ChiplistInput<object> {
    const chiplistInput = createChiplistInput('applications');
    chiplistInput.allowedValues = this.applicationNamesList;
    chiplistInput.hasAutocomplete = true;
    chiplistInput.formControl = this.optionalConfigFormGroup.get('application_input_value') as UntypedFormControl;
    chiplistInput.getElementFromValue = (value) => {
      return this.applicationNameToApplicationIdMap.get(value);
    };
    chiplistInput.getDisplayValue = (value: string) => {
      const appName = this.applicationIdToApplicationMap.get(value)?.name;
      return appName ? appName : value;
    };
    return chiplistInput;
  }

  private getResourceMembersChiplistInput(): ChiplistInput<object> {
    const resourceMembersColumn = getDefaultResourceMembersColumn<any>(this.resourceIdToResourceMap, this.resourceNameAndTypeToResourceMap);

    const chiplistInput = createChiplistInput('resource_members');
    chiplistInput.allowedValues = this.resourceMembersList;
    chiplistInput.hasAutocomplete = true;
    chiplistInput.formControl = this.resourceMembersFormGroup.get('resource_input_value') as UntypedFormControl;
    chiplistInput.getElementFromValue = resourceMembersColumn.getElementFromValue;
    chiplistInput.getDisplayValue = resourceMembersColumn.getDisplayValue;
    return chiplistInput;
  }

  public removeResourceChip(chipValue: ResourceMember): void {
    this.currentModel.resource_members = this.currentModel.resource_members.filter((resourceMember) => resourceMember !== chipValue);
  }

  public removeApplicationChip(chipValue: string): void {
    this.currentModel.applications = this.currentModel.applications.filter((appId) => appId !== chipValue);
  }

  public getResourceMemberNamesListString(): string {
    const resourceMemberNames = this.currentModel.resource_members.map((resourceMember) => {
      const resource = this.resourceIdToResourceMap.get(resourceMember.id);
      return `"${getResourceNameAndTypeString(resource.spec.name, resource.spec.resource_type)}"`;
    });
    return resourceMemberNames.join(', ');
  }

  public getApplicationNamesListString(): string {
    const applicationNames = this.currentModel.applications.map((appId) => `"${this.applicationIdToApplicationMap.get(appId).name}"`);
    return applicationNames.join(', ');
  }

  public canDeactivate(): Observable<boolean> | boolean {
    this.resetModel();
    return true;
  }
}

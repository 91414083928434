import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef,
  OnDestroy,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { NotificationService } from '@app/core';
import { sortArrayByKey, addNewEntryFocus, getFocusClass, capitalizeFirstLetter, generateRandomUuid } from '@app/shared/components/utils';
import { FilterManager } from '../filter/filter-manager';
import { ColumnTypes } from '../column-types.enum';
import {
  Column,
  CheckBoxColumn,
  SelectColumn,
  AutoInputColumn,
  ChiplistColumn,
  setColumnFormControlFilters,
  InputColumn,
  handleExpandColumn,
  handleCollapseColumn,
} from './column-definitions';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { ButtonType } from '../button-type.enum';
import { isImageFile, isTextFile, isNotTextOrImageFile, getFile } from '../file-utils';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { DropdownSelector } from '../dropdown-selector/dropdown-selector-utils';
import { ConfirmationDialogComponent, ConfirmationDialogData } from '../confirmation-dialog/confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TableButton } from '../buttons/table-button/table-button.component';
import { TableElement } from './table-element';
import { DialogActionEnum } from '../dialog-action.enum';
import { DialogAction } from '../dialog-types';
import { createDialogData } from '../dialog-utils';
import { IconColor } from '../icon-color.enum';
import { PaginatorConfig, TablePaginatorComponent } from '../table-paginator/table-paginator.component';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ParamSpecificFilterManager } from '../param-specific-filter-manager/param-specific-filter-manager';
import { getColumnDefs, getDisplayedColumnNames, isAtLeastOneRowSelected, openRightClickMenu } from '../table-layout-utils';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { InputSize } from '../custom-chiplist-input/input-size.enum';
import { ChiplistInput } from '../custom-chiplist-input/chiplist-input';
import { PageInfoWidthEnum, TitleSizeEnum } from '../page-info/page-info.component';
import { FilterMenuOption } from '../table-filter/table-filter.component';
import { getDefaultCreateDemoTourAnchor } from '../tour.utils';
import { FormLayoutComponent } from '../form-layout/form-layout.component';

export interface TableCellFormControl {
  formControl: UntypedFormControl;
  filteredValues?: Observable<Array<any>>;
}

@Component({
  selector: 'portal-table-layout',
  templateUrl: './table-layout.component.html',
  styleUrls: ['./table-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TableLayoutComponent<T extends TableElement> implements OnInit, OnChanges, OnDestroy {
  @Input() public tableData: Array<any> = [];
  @Input() public columnDefs: Map<string, Column<T>> = new Map();
  @Input() public makeEmptyTableElement: () => any;
  @Input() public filterMenuOptions: Map<string, FilterMenuOption> = new Map();
  @Input() public filterManager: FilterManager;
  @Input() public paramSpecificFilterManager: ParamSpecificFilterManager;
  @Input() public sortDataBy = 'index';
  @Input() public rowObjectName = '';
  @Input() public fixedTable = false;
  @Input() public readonlyInputs = false;
  @Input() public doNotPluralizeRowObjectName = false;
  // For setting enter key to change input focus.
  @Input() public keyTabManager: KeyTabManager = new KeyTabManager();
  @Input() public isUploading = false;
  @Input() public buttonsToShow: Array<ButtonType> = [ButtonType.ADD, ButtonType.DELETE];
  @Input() public preventAddNewRow = false;
  @Input() public disableAddNewRow = false;
  @Input() public dragAndDropRows = false;
  @Input() public dragAndDropHeaderOnly = false;
  @Input() public paginatorConfig = new PaginatorConfig<any>();
  @Input() public dropdownSelector: DropdownSelector;
  @Input() public linkDataSource = true;
  @Input() public hasBlankOption = false;
  @Input() public hideFilter = false;
  @Input() public deleteRowsFunc = this.deleteRows.bind(this);
  @Input() public warnOnNOperations = 2;
  @Input() public customButtons: Array<TableButton>;
  @Input() public resetButtonTooltipText = '';
  @Input() public defaultColumnSort = false;
  @Input() public dataLength: number;
  @Input() public useBackendFilter = false;
  @Input() public addExtraHeaderPadding = false;
  @Input() public pageTitle = '';
  @Input() public pageDescriptiveText = '';
  @Input() public productGuideLink = '';
  @Input() public pageInfoWidth = PageInfoWidthEnum.standard;
  @Input() public titleSize = TitleSizeEnum.standard;
  @Input() public isNestedTable = false;
  @Input() public getDisplayedColumnNames = getDisplayedColumnNames;
  @Input() public showDemoButtons = false;
  @Input() public tourAnchorId = getDefaultCreateDemoTourAnchor();
  @Input() public overrideShowDemoConnectorCheck: boolean = false;
  @Input() public overrideApiResourceCheckValue: boolean | undefined = undefined;
  @Input() public delayShowDemo = false;
  @Input() public removeFromAllowedValues: (element: T, chiplistInput: ChiplistInput<T>, optionValue: string) => boolean;
  @Input() public getCustomDeleteDialogMessage: (elements: Array<T>) => string;
  @Input() public hasCustomDeleteDialogMessage = false;
  @Input() public type: string;
  @Input() public alternateRowColor = false;
  @Input() public largeHeaders = false;
  @Input() public selectAllRowsTooltipText = 'Select all rows';
  @Input() public selectSingleRowTooltipText = 'Select row';
  @Input() public selectOnRowClick = false;
  @Output() public updateEvent = new EventEmitter<any>();
  @Output() public removeSelected = new EventEmitter<any>();
  @Output() public enableSelected = new EventEmitter<any>();
  @Output() public disableSelected = new EventEmitter<any>();
  @Output() public resetSelected = new EventEmitter<any>();
  @Output() public removeSelectedFromAutoCreate = new EventEmitter<any>();
  @Output() private refreshDataSource = new EventEmitter<any>();
  @Output() public updateSelection = new EventEmitter<any>();
  @Output() public updateMultipleSelection = new EventEmitter<any>();
  @Output() public updateAutoInput = new EventEmitter<any>();
  @Output() public selectionClose = new EventEmitter<any>();
  @Output() private uploadFile = new EventEmitter<any>();
  @Output() public addFiles = new EventEmitter<any>();
  @Output() public replaceFile = new EventEmitter<any>();
  @Output() public approveSelected = new EventEmitter<any>();
  @Output() public rejectSelected = new EventEmitter<any>();
  @Output() public filterSearchDelay = new EventEmitter<any>();
  @Output() public filterBySearchParam = new EventEmitter<any>();
  @Output() public startTourInParent = new EventEmitter<any>();
  @Output() public triggerRowDirtyEvent = new EventEmitter<Column<T>>();
  @Output() public triggerRowCheckedEvent = new EventEmitter<any>();
  @Output() public doOnPageEvent = new EventEmitter<any>();
  @Output() public preventAddNewRowFunc = new EventEmitter<any>();
  /**
   * The index of the element that is expanded. Otherwise, null.
   */
  public expandedElementId: number | null = null;
  private selectedColumn: Column<T>;
  public rightClickMenuId = `right-click-menu-button-${generateRandomUuid()}`;
  public rightClickMenuIdNested = `${this.rightClickMenuId}-nested`;

  // This is required in order to reference the enums in the html template.
  public columnTypes = ColumnTypes;
  public inputSize = InputSize;
  public iconColor = IconColor;

  public dataSource: MatTableDataSource<any> = new MatTableDataSource(this.tableData);

  public capitalizeFirstLetter = capitalizeFirstLetter;

  @ViewChild('auto', { static: false }) public matAutocomplete: MatAutocomplete;
  @ViewChild('tableSort', { static: true }) public tableSort: MatSort;
  @ViewChild(TablePaginatorComponent, { static: true }) public paginator: TablePaginatorComponent<any>;
  @ViewChild(MatMenuTrigger) public trigger: MatMenuTrigger;
  @ViewChild('triggerAutoInput', { static: false }) public matAutoInputTrigger: MatAutocompleteTrigger;
  @ViewChild('rightClickMenuTrigger') public rightClickMenuTrigger: MatMenuTrigger;
  @ViewChildren('nestedFormLayout') private nestedFormLayouts: QueryList<FormLayoutComponent<T>>;
  @ViewChildren('nestedTable') private nestedTables: QueryList<TableLayoutComponent<T>>;

  public addNewEntryFocus = addNewEntryFocus;
  public getFocusClass = getFocusClass;
  public isImageFile = isImageFile;
  public isTextFile = isTextFile;
  public isNotTextOrImageFile = isNotTextOrImageFile;
  public getColumnDefs = getColumnDefs;
  public isAtLeastOneRowSelected = isAtLeastOneRowSelected;
  public openRightClickMenu = openRightClickMenu;

  constructor(private notificationService: NotificationService, private changeDetector: ChangeDetectorRef, private dialog?: MatDialog) {}

  public ngOnInit(): void {
    setColumnFormControlFilters(this.columnDefs);
    if (this.defaultColumnSort) {
      this.tableSort.sort({ id: this.sortDataBy, start: 'asc', disableClear: true });
    }
  }

  public ngOnChanges(): void {
    this.updateDataSource(this.tableData);
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
  }

  public updateDataSource(newData: Array<any>): void {
    if (this.expandedElementId !== undefined && this.expandedElementId !== null) {
      // When the data refreshes we need to ensure the expanded row icon displays correctly:
      const expandedRow = newData[this.expandedElementId];
      if (!!expandedRow) {
        expandedRow.isRowExpanded = true;
      }
    }
    this.dataSource = new MatTableDataSource(sortArrayByKey(newData, this.sortDataBy));
    this.dataSource.sort = this.tableSort;
    if (this.linkDataSource) {
      // This links the paginator length to the datasource length.
      this.dataSource.paginator = this.paginator.paginator;
    }
    if (!this.hideFilter) {
      this.filterManager.createNestedFilterPredicate(this.dataSource, this.columnDefs);
      this.filterManager.applyFilter(this.dataSource);
    }
  }

  public removeChip(chipValue: any, element: T, column: ChiplistColumn<T>): void {
    element[column.name] = element[column.name].filter((value) => column.getDisplayValue(value) !== column.getDisplayValue(chipValue));
    this.markRowAsDirty(element, column);
    this.changeDetector.detectChanges();
  }

  public async onFormFieldEdit(eventTarget: HTMLInputElement, element: T, columnDef: Column<T>): Promise<void> {
    if (columnDef.isReadOnly(element) || !columnDef.isEditable) {
      return;
    }
    let targetValue = eventTarget.value;
    // If field is blank, notify the user that field is required and
    // reset the value of the field to its original value
    if (!!columnDef.getFormattedValue) {
      targetValue = columnDef.getFormattedValue(targetValue, element, columnDef);
    }
    if (targetValue === '' && columnDef.requiredField(element, columnDef)) {
      this.notificationService.error(columnDef.displayName + ' is a required field');
      if (!element.isNew) {
        // We do not allow removing a required field from an existing row, so reset the value
        eventTarget.value = element[columnDef.name];
        element.resetIsValid = true;
      } else {
        // If the row is new and unsaved, we can remove the required value
        element[columnDef.name] = '';
      }
      element.isValid = false;
      return;
    }
    // If existing value is null, undefined or empty string and the new input value is an empty string,
    // do not update the database.
    if (!element[columnDef.name] && targetValue === '') {
      return;
    }
    // Do not proceed if the value has not been changed.
    const originalTableValue = columnDef.getDisplayValue(element);
    if (
      originalTableValue !== null &&
      originalTableValue !== undefined &&
      originalTableValue.toString() === targetValue.toString() &&
      (await this.checkValidEntry(targetValue, columnDef, element))
    ) {
      element.isValid = true;
      return;
    }
    const previousValue = element[columnDef.name];
    // Update the element with the new value:
    element[columnDef.name] = targetValue;
    if (this.checkIfDuplicateEntry(element, columnDef)) {
      // Reset the element to the previous value if it is a duplicate:
      element[columnDef.name] = previousValue;
      // Reset the form input to the previous value if it is a duplicate:
      eventTarget.value = previousValue;
      element.isValid = false;
      return;
    }
    // Element was not reset, so mark as dirty:
    this.markRowAsDirty(element, columnDef);
    // Do not proceed if the new value is invalid.
    if (!(await this.checkValidEntry(targetValue, columnDef, element))) {
      // Do not proceed if the new value is invalid.
      element.isValid = false;
      return;
    }
    if (!(await this.checkAllFieldsInRowAreValid(element))) {
      // Do not proceed if any values in the element(row) are invalid.
      element.isValid = false;
      return;
    }
    if (this.checkAllRequiredFieldsCompleteAndNotifyUser(element, false)) {
      element.isValid = true;
    } else {
      element.isValid = false;
    }
  }

  private async checkValidEntry(value: string | boolean, column: Column<T>, element: T): Promise<boolean> {
    if (await this.isFieldValidValue(value, element, column)) {
      return true;
    }
    let validationErrorMessage = `${value} is not a valid entry for "${column.displayName}"`;
    if (!!column.getCustomValidationErrorMessage) {
      validationErrorMessage = column.getCustomValidationErrorMessage(value, element, column);
    }
    this.notificationService.error(validationErrorMessage);
    return false;
  }

  /**
   * Checks if the field has been updated with a value that already exists
   * in the data source and notifies the user if it is a duplicate
   */
  private checkIfDuplicateEntry(element: T, columnDef: Column<T>): boolean {
    if (columnDef.isUnique) {
      let matches = 0;
      for (const item of this.dataSource.data) {
        if (columnDef.isCaseSensitive) {
          if (item[columnDef.name] === element[columnDef.name]) {
            matches++;
          }
        } else {
          if (item[columnDef.name].toLowerCase() === element[columnDef.name].toLowerCase()) {
            matches++;
          }
        }
      }
      if (matches > 1) {
        this.notificationService.error(element[columnDef.name] + ' already exists.');
        this.refreshDataSourceEventFunc();
        return true;
      }
    }
    return false;
  }

  public addNewRowToTable(): void {
    if (this.preventAddNewRow) {
      this.preventAddNewRowFunc.emit();
      return;
    }
    // Create the new table row
    const newTableRow = this.makeEmptyTableElement();
    // Reassigning the data source triggers the refresh of the table
    this.dataSource.data.unshift(newTableRow);
    this.dataSource.data = sortArrayByKey(this.dataSource.data, this.sortDataBy);
    this.paginator.rowAdded = true;
  }

  private closeExpandedRowOnDelete(elementsToRemove: Array<T>): void {
    for (const row of elementsToRemove) {
      if (row.index === this.expandedElementId) {
        this.expandedElementId = null;
      }
    }
  }

  public deleteRows(): void {
    const elementsToRemove = this.getFilteredCheckedElements();
    if ((elementsToRemove.length >= this.warnOnNOperations || this.hasCustomDeleteDialogMessage) && this.dialog) {
      const dialogAction: DialogAction = {
        action: DialogActionEnum.deleting,
        objectName: this.rowObjectName,
        eventToEmit: this.removeSelected,
      };
      this.openConfirmationDialog(elementsToRemove, dialogAction);
    } else {
      this.removeSelectedEventFunc(elementsToRemove);
      this.closeExpandedRowOnDelete(elementsToRemove);
    }
  }

  public enableRows(): void {
    const elementsToEnable = this.getFilteredCheckedElements();
    this.enableSelectedEventFunc(elementsToEnable);
  }

  public disableSelectedFunc(elementsToRemove: Array<T>): void {
    this.disableSelected.emit(elementsToRemove);
  }

  public disableRows(): void {
    const elementsToRemove = this.getFilteredCheckedElements();
    this.disableSelectedFunc(elementsToRemove);
  }

  public resetSelectedFunc(elementsToReset: Array<T>): void {
    this.resetSelected.emit(elementsToReset);
  }

  public resetRows(): void {
    const elementsToReset = this.getFilteredCheckedElements();
    if (elementsToReset.length >= this.warnOnNOperations && this.dialog) {
      const dialogAction: DialogAction = {
        action: DialogActionEnum.resetting,
        objectName: this.rowObjectName,
        eventToEmit: this.resetSelected,
      };
      this.openConfirmationDialog(elementsToReset, dialogAction);
    } else {
      this.resetSelectedFunc(elementsToReset);
    }
  }

  public customControlFunc(customButton: TableButton): void {
    const selectedElements = this.getFilteredCheckedElements();
    customButton.callback(selectedElements);
  }

  public getFilteredCheckedElements(): Array<T> {
    return this.dataSource.filteredData.filter((element) => element.isChecked);
  }

  public getUncheckedElements(): Array<T> {
    const filteredCheckedElements = this.getFilteredCheckedElements();
    return this.dataSource.data.filter((element) => !filteredCheckedElements.includes(element));
  }

  /**
   * Checks if all required fields are completed for the newly added row and will notify the user
   * if flag is set to true
   */
  private checkAllRequiredFieldsCompleteAndNotifyUser(updatedElement: T, notifyUser: boolean): boolean {
    if (updatedElement.overrideRequiredFlag) {
      // This is a temporary solution to a larger issuer which requires a larger refactoring of
      // the table-layout.
      return true;
    }
    for (const column of Array.from(this.columnDefs.values())) {
      if (!column.requiredField(updatedElement, column)) {
        continue;
      }
      if (
        (!updatedElement[column.name] && updatedElement[column.name] !== 0) ||
        (Array.isArray(updatedElement[column.name]) && updatedElement[column.name].length === 0)
      ) {
        if (notifyUser) {
          this.notificationService.error(`Row was not saved. Value for ${column.displayName} is required.`);
        }
        return false;
      }
    }
    return true;
  }

  /**
   * Checks if all fields/columns in the table row are valid. Please note, since chiplist
   * columns are a list/array of values, the validation is done when adding to the list
   * rather than here on row validation.
   */
  private async checkAllFieldsInRowAreValid(updatedElement: T): Promise<boolean> {
    const parentColumnDefsAsArray = Array.from(this.columnDefs.values());
    const nestedFormColumnDefsAsArray = !!updatedElement.nestedFormColumnDefs
      ? Array.from(updatedElement.nestedFormColumnDefs.values())
      : [];
    const allColumnDefsAsArray = [...parentColumnDefsAsArray, ...nestedFormColumnDefsAsArray];
    for (const column of allColumnDefsAsArray) {
      if (column.type === ColumnTypes.CHIPLIST) {
        const chiplistColumn = column as ChiplistColumn<T>;
        if (chiplistColumn.isFormInputDirty) {
          return false;
        }
        continue;
      }
      let value = updatedElement[column.name];
      if (column.type === ColumnTypes.AUTOINPUT) {
        const formControl = value as UntypedFormControl;
        value = formControl.value;
      } else if (column.getDisplayValue) {
        value = column.getDisplayValue(updatedElement);
      }
      if (!(await this.isFieldValidValue(value, updatedElement, column))) {
        let validationErrorMessage = `Row was not saved. Value for "${column.displayName}" is not valid.`;
        if (!!column.getCustomValidationErrorMessage) {
          validationErrorMessage = column.getCustomValidationErrorMessage(value, updatedElement, column);
        }
        this.notificationService.error(validationErrorMessage);
        return false;
      }
    }
    return true;
  }

  public toggleIsChecked(element: T): void {
    element.isChecked = !element.isChecked;
    this.triggerRowCheckedEvent.emit();
  }

  public areAllSelected(): boolean {
    if (this.dataSource.data.length === 0) {
      return false;
    }
    const selectRowColumn = this.columnDefs.get('selectRow');
    for (const element of this.dataSource.data) {
      if (!element.isChecked && !selectRowColumn.disableField(element)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Selects all rows if they are not all selected; otherwise unselects all
   */
  public masterToggle(): void {
    const selectRowColumn = this.columnDefs.get('selectRow');
    this.areAllSelected()
      ? this.dataSource.data.forEach((element) => (element.isChecked = false))
      : this.dataSource.data.forEach((element) =>
          !selectRowColumn.disableField(element) ? (element.isChecked = true) : (element.isChecked = false)
        );
    this.triggerRowCheckedEvent.emit();
  }

  public checkIfNewRowExists(): boolean {
    if (this.dataSource.data.length === 0) {
      return false;
    }
    return this.dataSource.data[0].isNew;
  }

  private tabIfColumnFreezeWhenSet(inputId: string): void {
    const allElements = this.keyTabManager.getElements();
    const currentElementIndex = this.keyTabManager.getCurrentElementIndex(inputId, allElements);
    let handleKeyup;
    let handleClick;
    handleKeyup = () => {
      this.keyTabManager.keyTabByIndex(currentElementIndex);
      window.removeEventListener('click', handleClick);
    };
    handleClick = () => {
      this.onAutoInputOptionClick(inputId);
      window.removeEventListener('keyup', handleKeyup);
    };
    window.addEventListener('click', handleClick, { once: true });
    window.addEventListener('keyup', handleKeyup, { once: true });
  }

  public async onAutoInputFormFieldEdit(
    eventTarget: HTMLInputElement,
    element: T,
    column: AutoInputColumn<T>,
    inputId: string
  ): Promise<void> {
    // We only call setTimeout if autocomplete is open since if it is closed
    // the user has not entered a value that exists in the dropdown.
    if (!this.matAutoInputTrigger.autocomplete.showPanel) {
      await this.updateValueOnAutoInputEdit(eventTarget, element, column, inputId);
    } else {
      // When selecting an option from the autocomplete dropdown, the 'blur' event fires first,
      // then the 'optionSelected' event is fired. We need to delay the blur event so that
      // the value is updated before the element is updated, which results in possibly
      // the wrong value being submmited.
      setTimeout(async () => {
        await this.updateValueOnAutoInputEdit(eventTarget, element, column, inputId);
      }, 200);
    }
  }

  private async updateValueOnAutoInputEdit(
    eventTarget: HTMLInputElement,
    element: T,
    column: AutoInputColumn<T>,
    inputId: string
  ): Promise<void> {
    if (!column.allowAnyValue && eventTarget.value !== '' && !(await this.isFieldValidValue(eventTarget.value, element, column))) {
      element.isValid = false;
      this.notificationService.error(`"${eventTarget.value}" is not a valid option`);
      return;
    }
    if (column.freezeWhenSet) {
      this.tabIfColumnFreezeWhenSet(inputId);
    }
    this.updateAutoInputEventFunc({ optionValue: eventTarget.value, column, element });
  }

  public updateInputOnAutoInputSelection(optionValue: string, input: HTMLInputElement): void {
    input.value = optionValue;
  }

  /**
   * Will be called when an auto input option is selected by
   * a click event. This action replaces the current autocomplete
   * element with a basic readonly input element. Because the element
   * being 'tabbed' on is removed from the DOM and replaced by a new
   * input element, the new id of the 'current' input will be incremented
   * by one. Therefore, we need to 'tab' using this new id.
   * Otherwise, a search for the original input id will return undefined.
   */
  private onAutoInputOptionClick(inputId: string): void {
    const nextInputId = this.getNextInputId(inputId);
    this.keyTabManager.keyTab(nextInputId);
  }

  public onSelection(params: { value: string | Array<string>; column: SelectColumn<T>; element: T }): void {
    this.markRowAsDirty(params.element, params.column);
    if (params.column.multiple) {
      this.updateMultipleSelectionEventFunc(params);
      return;
    }
    this.updateSelectionEventFunc(params);
  }

  public onSelectionToggle(event: boolean, column: SelectColumn<T>, element: T): void {
    // The event will return true on open and false on close.
    if (event) {
      return;
    }
    this.selectionCloseEventFunc({ column, element });
  }

  public getSelectionDisplayValue(column: SelectColumn<T>, element: T): string | Array<string> {
    if (column.multiple) {
      return column.getMultipleDisplayValues(element);
    }
    return column.getDisplayValue(element);
  }

  /**
   * Checks if the option from the autocomplete dropdown has already been
   * added to the chiplist.
   * @param element is the selected row from the table.
   * @param column is the selected column from the table.
   * @param optionValue is the displayed value of the dropdown option.
   */
  public isOptionAlreadySelected(element: T, column: Column<T>, optionValue: string): boolean {
    if (!Array.isArray(element[column.name])) {
      return optionValue === element[column.name];
    }
    for (const item of element[column.name]) {
      if (column.getDisplayValue(item) === optionValue) {
        return true;
      }
    }
    return false;
  }

  public async onCheckboxUpdate(column: CheckBoxColumn<T>, element: T, isBoxChecked: boolean): Promise<void> {
    // Do not proceed if the new value is invalid.
    if (!(await this.checkValidEntry(isBoxChecked, column, element))) {
      return;
    }
    column.setElementFromCheckbox(element, isBoxChecked);
    this.markRowAsDirty(element, column);
  }

  public getNextInputId(inputId: string): string {
    const inputIdSplitArr = inputId.split('-');
    const lastIndex = inputIdSplitArr.length - 1;
    const nextInputIdNumber = parseInt(inputIdSplitArr[lastIndex], 10) + 1;
    inputIdSplitArr.splice(lastIndex, 1);
    const nextInputId = inputIdSplitArr.join('-') + '-' + nextInputIdNumber.toString();
    return nextInputId;
  }

  public uploadFileEvent(event: any): void {
    this.uploadFile.emit(event);
  }

  public handleAddDrop(event: any): void {
    const dt = event.dataTransfer;
    const files = dt.files;
    this.addFilesEventFunc(files);
  }

  public handleReplaceDrop(event: any, index: number): void {
    const targetTableElement = this.tableData[index];
    const dt = event.dataTransfer;
    const files = dt.files;
    if (files.length !== 1) {
      this.tableData[index].isValid = false;
      this.notificationService.error('Cannot replace a file with multiple files. Please select a single file.');
      return;
    }
    this.replaceExistingFile(targetTableElement, files[0]);
  }

  private replaceExistingFile(updatedElement: T, file: File): void {
    this.replaceFileEventFunc({ updatedElement, file });
  }

  public replaceFileAction(event: any, element: T): void {
    const file = getFile(event);
    this.replaceExistingFile(element, file);
  }

  private isOneChipRequired(element: T, column: Column<T>): boolean {
    if (column.requiredField(element, column)) {
      return true;
    }
    return false;
  }

  public hasOnlyOneRequiredChip(column: Column<T>, element: T): boolean {
    if (!element[column.name]) {
      return false;
    }
    if (this.isOneChipRequired(element, column) && element[column.name].length === 1) {
      return true;
    }
    return false;
  }

  public isChipRemovable(column: ChiplistColumn<T>, element: T): boolean {
    return column.isRemovable && !this.hasOnlyOneRequiredChip(column, element);
  }

  public getTableInputAppearance(): string {
    if (this.readonlyInputs) {
      return 'none';
    }
    return 'outline';
  }

  public approveRows(): void {
    const elementsToAccept = this.getFilteredCheckedElements();
    this.approveSelectedEventFunc(elementsToAccept);
  }

  public rejectRows(): void {
    const elementsToReject = this.getFilteredCheckedElements();
    this.rejectSelectedEventFunc(elementsToReject);
  }

  private openConfirmationDialog(elements: Array<T>, dialogAction: DialogAction): void {
    const dialogData = this.createRowSelectionDialogData(elements, dialogAction);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        dialogAction.eventToEmit.emit(elements);
        if (dialogAction.action === DialogActionEnum.deleting) {
          this.closeExpandedRowOnDelete(elements);
        }
        return;
      }
    });
  }

  private createRowSelectionDialogData(elements: Array<T>, dialogAction: DialogAction): ConfirmationDialogData {
    let messagePrefix = capitalizeFirstLetter(dialogAction.action) + ' ' + elements.length + ' ' + dialogAction.objectName.toLowerCase();
    if (elements.length > 1) {
      messagePrefix += +this.doNotPluralizeRowObjectName ? '' : 's';
    }
    let message = 'Warning: You are ' + dialogAction.action.toLowerCase();
    if (elements.length > 1) {
      message += ' multiple ' + dialogAction.objectName.toLowerCase() + (this.doNotPluralizeRowObjectName ? '. ' : 's. ');
    } else {
      message += ' the selected ' + dialogAction.objectName.toLowerCase() + '. ';
    }
    if (this.hasCustomDeleteDialogMessage) {
      message += this.getCustomDeleteDialogMessage(elements);
    }
    message += 'Do you want to continue?';
    return createDialogData(messagePrefix, message);
  }

  public async updateRow(element: T): Promise<void> {
    if (
      element.dirty &&
      this.checkAllRequiredFieldsCompleteAndNotifyUser(element, true) &&
      (await this.checkAllFieldsInRowAreValid(element))
    ) {
      // This will ensure the red "required" boxes will be removed.
      element.isNew = false;
      element.isValid = true;
      element.dirty = false;
      // Emit the event back to the parent class where it can make
      // the appropriate api call.
      this.updateEventFunc(element);
    }
  }

  public delayAndUpdateRow(event: any, element: T): void {
    const targetCellIsInSameRow: boolean = event.currentTarget.contains(event.relatedTarget);
    const isSelectingFromDropdown: boolean = event.relatedTarget?.classList.contains('mat-option');
    // Do not update yet if the event was caused by clicking a cell within the same row or selecting from a dropdown.
    if (targetCellIsInSameRow || isSelectingFromDropdown) {
      return;
    }
    setTimeout(async () => {
      await this.updateRow(element);
    }, 500);
  }

  public handleNestedRowBlur(event: any, element: T): void {
    const currentTarget = event.currentTarget;
    setTimeout(() => {
      // We need this delay so that the focus is placed back on the clicked element rather than
      // on the html body, since it will be automatically placed on the html body after first clicking.
      const targetCellIsInSameRow: boolean = currentTarget.contains(document.activeElement);
      const isSelectingFromDropdown: boolean = event.relatedTarget?.classList.contains('mat-option');
      // Do not update yet if the event was caused by clicking a cell within the same row or selecting from a dropdown.
      if (targetCellIsInSameRow || isSelectingFromDropdown) {
        return;
      }
      setTimeout(async () => {
        await this.updateRow(element);
      }, 500);
    }, 500);
  }

  public displayDropdownSelector(): boolean {
    return !this.fixedTable && !!this.dropdownSelector;
  }

  private sortByOptionValue(allowedValues: Array<any>, column: SelectColumn<T>, element: T): Array<any> {
    return allowedValues.sort((lhs: any, rhs: any) => {
      return column.getOptionDisplayValue(lhs, element).localeCompare(column.getOptionDisplayValue(rhs, element));
    });
  }

  public getAllowedValues(column: Column<T>, element: T): Array<any> {
    let allowedValues = column.getAllowedValues(element);
    if (!!allowedValues) {
      const sortedAllowedValues = this.sortByOptionValue(allowedValues, column, element);
      return sortedAllowedValues;
    }
    allowedValues = column.allowedValues;
    if (!allowedValues) {
      return [];
    }
    const sortedAllowedValues = this.sortByOptionValue(allowedValues, column, element);
    return sortedAllowedValues;
  }

  public getSortedFiltredAutocompleteInputValues(column: AutoInputColumn<T>, filteredList: Array<any>): Array<any> {
    const sortedFilteredList = filteredList.sort((lhs: any, rhs: any) => {
      return column.getDisplayValue(lhs).localeCompare(column.getDisplayValue(rhs));
    });
    return sortedFilteredList;
  }

  public updateEventFunc(element: T): void {
    this.updateEvent.emit(element);
  }

  public removeSelectedEventFunc(elementsToRemove: Array<T>): void {
    this.removeSelected.emit(elementsToRemove);
  }

  public enableSelectedEventFunc(elementsToEnable: Array<T>): void {
    this.enableSelected.emit(elementsToEnable);
  }

  public refreshDataSourceEventFunc(): void {
    this.refreshDataSource.emit();
  }

  public updateSelectionEventFunc(params: { value: string | Array<string>; column: SelectColumn<T>; element: T }): void {
    this.updateSelection.emit(params);
  }

  public updateMultipleSelectionEventFunc(params: { value: string | Array<string>; column: SelectColumn<T>; element: T }): void {
    this.updateMultipleSelection.emit(params);
  }

  public updateAutoInputEventFunc(params: { optionValue: string; column: AutoInputColumn<T>; element: T }): void {
    this.updateAutoInput.emit({ optionValue: params.optionValue, column: params.column, element: params.element });
  }

  public selectionCloseEventFunc(params: { column: SelectColumn<T>; element: T }): void {
    this.selectionClose.emit({ column: params.column, element: params.element });
  }

  public addFilesEventFunc(files: any): void {
    this.addFiles.emit(files);
  }

  public replaceFileEventFunc(params: { updatedElement: T; file: File }): void {
    this.replaceFile.emit({ updatedElement: params.updatedElement, file: params.file });
  }

  public approveSelectedEventFunc(elementsToAccept: Array<T>): void {
    this.approveSelected.emit(elementsToAccept);
  }

  public rejectSelectedEventFunc(elementsToReject: Array<T>): void {
    this.rejectSelected.emit(elementsToReject);
  }

  public filterSearchDelayEventFunc(event: any): void {
    this.filterSearchDelay.emit(event);
  }

  public filterBySearchParamEventFunc(): void {
    this.filterBySearchParam.emit();
  }

  /**
   * When an element is clicked, it will expand that element while closing any existing
   * expanded elements. If the existing expanded element is clicked, all elements are closed.
   * If no elements, then nothing is expanded.
   */
  public toggleRow(element: T): void {
    this.expandedElementId = this.expandedElementId === element.index ? null : element.index;
  }

  public toggleRowExpansionIcon(element: T, column: Column<T>): void {
    if (!!column?.disableField(element)) {
      return;
    }
    this.toggleRow(element);
    for (const item of this.tableData) {
      item.isRowExpanded = item.index === this.expandedElementId;
    }
  }

  public toggleRowOnRowClick(element: T): void {
    const expandColumn = this.columnDefs.get('expandRow');
    if (element.index === this.expandedElementId) {
      return;
    }
    this.toggleRowExpansionIcon(element, expandColumn);
  }

  public preventDefaultKeyDownEvent(event: any): void {
    event.preventDefault();
  }

  public isExpandableRow(element: T): boolean {
    const expandColumn = this.columnDefs.get('expandRow');
    return !!this.isNestedTable && !element.isRowExpanded && !expandColumn.disableField(element);
  }

  public getHeaderTooltipText(column: Column<T>, element?: T): string {
    let tooltipText = '';
    if (column.getHeaderTooltip) {
      tooltipText += column.getHeaderTooltip(element);
    }
    if (column.requiredField()) {
      if (tooltipText === '') {
        tooltipText += 'This is a required field';
      } else if (tooltipText.endsWith('.')) {
        tooltipText += ' This is a required field.';
      } else {
        tooltipText += '. This is a required field.';
      }
    }
    return tooltipText;
  }

  public async isFieldValidValue(value: string | boolean, element: T, column: Column<T>): Promise<boolean> {
    if (value === undefined || value === null || value === '') {
      return !column.requiredField(element, column);
    }
    if (!column.isValidEntry) {
      // We are not checking for valid entry
      return true;
    }
    const isValidEntryResult = await Promise.resolve(column.isValidEntry(value, element, column));
    return isValidEntryResult;
  }

  public changePasswordVisibility(column: InputColumn<T>): void {
    if (column.inputType === 'password') {
      column.inputType = 'text';
    } else {
      column.inputType = 'password';
    }
  }

  public getRowDeletionTooltipText(element: T, column: Column<T>): string {
    if (!!column.disableField(element)) {
      return 'Deletion of this row is not permitted';
    }
    return this.selectSingleRowTooltipText;
  }

  public getDataSourceData(): Array<T> {
    return this.dataSource.data;
  }

  public showNestedTable(element: T): boolean {
    if (!element.expandedData) {
      return false;
    }
    if (this.expandedElementId === 0) {
      // Since numbers are valid values we need to allow for 0
      return true;
    }
    return !!this.expandedElementId;
  }

  public triggerChangeDetectionFromParentComponent(): void {
    const nestedFormLayoutsArray = !!this.nestedFormLayouts ? this.nestedFormLayouts.toArray() : [];
    for (const nestedFormLayout of nestedFormLayoutsArray) {
      nestedFormLayout.triggerFormResetFromParentComponent();
    }
    const nestedTableComponentsArray = !!this.nestedTables ? this.nestedTables.toArray() : [];
    for (const nestedTableComponent of nestedTableComponentsArray) {
      nestedTableComponent.triggerChangeDetectionFromParentComponent();
    }
    this.changeDetector.detectChanges();
  }

  public startDemoTour() {
    this.startTourInParent.emit();
  }

  private markRowAsDirty(element: T, column: Column<T>): void {
    element.dirty = true;
    this.triggerRowDirtyEvent.emit(column);
  }

  public triggerRowDirtyEventFunc(column: Column<T>): void {
    this.triggerRowDirtyEvent.emit(column);
  }

  public triggerRowCheckedEventFunc(column: Column<T>): void {
    this.triggerRowCheckedEvent.emit(column);
  }

  public onExpandColumn(column: Column<T>): void {
    handleExpandColumn(column, this.columnDefs);
  }

  public onCollapseColumn(): void {
    handleCollapseColumn(this.selectedColumn, this.columnDefs);
  }

  public onHeaderRightClick(event: MouseEvent, column: Column<T>): void {
    this.selectedColumn = column;
    openRightClickMenu(event, this.rightClickMenuId, this.rightClickMenuTrigger);
  }

  /**
   * Will return the table data from the current page only (what is shown on the screen)
   */
  public getCurrentPageTableData(): Array<T> {
    const skip = this.paginator.paginator.pageSize * this.paginator.paginator.pageIndex;
    return this.dataSource.filteredData.slice(skip, skip + this.paginator.paginator.pageSize);
  }

  public doOnPageEventFunc() {
    this.doOnPageEvent.emit();
  }
}

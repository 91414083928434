import { Connector, Organisation } from '@agilicus/angular';
import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { AppState, NotificationService } from '@app/core';
import {
  ActionApiApplicationsSubmittingSSHModel,
  ActionApiApplicationsResetSSHModel,
} from '@app/core/api-applications/api-applications.actions';
import { ApplicationModelStatus } from '@app/core/api-applications/api-applications.models';
import { selectApiApplicationsSshModel, selectApiApplicationsSshModelStatus } from '@app/core/api-applications/api-applications.selectors';
import { initConnectors } from '@app/core/connector-state/connector.actions';
import { selectConnectorList } from '@app/core/connector-state/connector.selectors';
import {
  getCredentialPasswordTooltipText,
  getCredentialPrivateKeyPassphraseTooltipText,
  getCredentialPrivateKeyTooltipText,
  getCredentialUsernameTooltipText,
} from '@app/core/credentials-utils';
import { SSHModel } from '@app/core/models/ssh/ssh-model';
import { selectCurrentOrganisation } from '@app/core/organisations/organisations.selectors';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { selectCanAdminIssuers } from '@app/core/user/permissions/issuers.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { delayStepperAdvanceOnSuccessfulApply, handleStateOnFirstStepSelection } from '../application-template-utils';
import { getFile, getPemFileErrorMessage, isValidPemFile, uploadDataFromFile } from '../file-utils';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { ResourceType } from '../resource-type.enum';
import { StepperType } from '../stepper-type.enum';
import { capitalizeFirstLetter, getEmptyStringIfUnset, modifyDataOnFormBlur, pluralizeString } from '../utils';
import {
  getSshCredentialPasswordMaxLength,
  getSshCredentialPrivateKeyMaxLength,
  getSshCredentialPrivateKeyPassphraseMaxLength,
} from '../validation-utils';

@Component({
  selector: 'portal-ssh-stepper',
  templateUrl: './ssh-stepper.component.html',
  styleUrls: ['./ssh-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SshStepperComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public currentSSHModel: SSHModel;
  private sshModelStatus: ApplicationModelStatus;
  public hasAppsPermissions: boolean;
  public hasUsersPermissions: boolean;
  public hasIssuersPermissions: boolean;
  public connectors: Array<Connector>;
  public connectorFormGroup: FormGroup;
  public sshNameFormGroup: FormGroup;
  public addressFormGroup: FormGroup;
  public optionalConfigFormGroup: FormGroup;
  public currentOrg: Organisation;
  public appModelSubmissionProgressBarController: ProgressBarController = new ProgressBarController();
  public stepperType = StepperType.ssh;
  public hasTemplates = false;
  public completedSSHText = `Your ssh setup is complete (you may need to assign user permissions). Please review it below. Make any corrections needed above.`;
  public pageDescriptiveText = `SSH (Secure Shell) is a remote command-line interface for system management.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/zero-trust-ssh-access/`;
  public orgId: string;
  public resourceType = ResourceType.ssh;

  public stepperState: { optionalConfig: boolean | undefined } = {
    optionalConfig: undefined,
  };
  public optionalConfigOptions: Array<{ name: string; value: boolean }> = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ];

  public capitalizeFirstLetter = capitalizeFirstLetter;
  public pluralizeString = pluralizeString;
  public getSshCredentialPrivateKeyMaxLength = getSshCredentialPrivateKeyMaxLength;
  public getSshCredentialPrivateKeyPassphraseMaxLength = getSshCredentialPrivateKeyPassphraseMaxLength;
  public getSshCredentialPasswordMaxLength = getSshCredentialPasswordMaxLength;
  public getCredentialPrivateKeyTooltipText = getCredentialPrivateKeyTooltipText;
  public getCredentialPrivateKeyPassphraseTooltipText = getCredentialPrivateKeyPassphraseTooltipText;
  public getCredentialPasswordTooltipText = getCredentialPasswordTooltipText;
  public getCredentialUsernameTooltipText = getCredentialUsernameTooltipText;

  public keyTabManager: KeyTabManager = new KeyTabManager();

  @ViewChild('sshStepper') public stepper: MatStepper;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private customValidatorsService: CustomValidatorsService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.resetModel();
    this.store.dispatch(initConnectors({ force: true, blankSlate: false }));
    const hasUsersPermissions$ = this.store.pipe(select(selectCanAdminUsers));
    const hasAppsPermissions$ = this.store.pipe(select(selectCanAdminApps));
    const hasIssuersPermissions$ = this.store.pipe(select(selectCanAdminIssuers));
    const currentOrg$ = this.store.pipe(select(selectCurrentOrganisation));
    const sshModelState$ = this.store.pipe(select(selectApiApplicationsSshModel));
    const sshModelStatusState$ = this.store.pipe(select(selectApiApplicationsSshModelStatus));
    const connectorListState$ = this.store.pipe(select(selectConnectorList));
    combineLatest([
      hasUsersPermissions$,
      hasAppsPermissions$,
      hasIssuersPermissions$,
      currentOrg$,
      sshModelState$,
      sshModelStatusState$,
      connectorListState$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          hasUsersPermissionsResp,
          hasAppsPermissionsResp,
          hasIssuersPermissionsResp,
          currentOrgResp,
          sshModelStateResp,
          sshModelStatusStateResp,
          connectorListStateResp,
        ]: [
          OrgQualifiedPermission,
          OrgQualifiedPermission,
          OrgQualifiedPermission,
          Organisation,
          SSHModel,
          ApplicationModelStatus,
          Array<Connector>
        ]) => {
          this.orgId = hasUsersPermissionsResp?.orgId;
          this.sshModelStatus = sshModelStatusStateResp;
          if (!this.currentSSHModel || this.sshModelStatus.complete) {
            this.currentSSHModel = cloneDeep(sshModelStateResp);
          }
          this.hasAppsPermissions = hasAppsPermissionsResp.hasPermission;
          this.hasUsersPermissions = hasUsersPermissionsResp.hasPermission;
          this.hasIssuersPermissions = hasIssuersPermissionsResp.hasPermission;
          this.connectors = connectorListStateResp;
          this.currentOrg = currentOrgResp;
          if (!this.sshModelStatus.saving) {
            this.initializeFormGroups();
          }
          delayStepperAdvanceOnSuccessfulApply(this.stepper, this.sshModelStatus);
        }
      );
  }

  private resetModel(): void {
    this.store.dispatch(new ActionApiApplicationsResetSSHModel());
  }

  public onStepperSelectionChange(selectedIndex: number): void {
    handleStateOnFirstStepSelection(selectedIndex, this.sshModelStatus, undefined, this.resetModel.bind(this), undefined);
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeFormGroups(): void {
    const sshModel = this.getModel();
    this.initializeConnectorFormGroup();
    this.initializeSSHNameFormGroup(sshModel);
    this.initializeAddressFormGroup(sshModel);
    this.initializeOptionalConfigFormGroup(sshModel);
    this.changeDetector.detectChanges();
  }

  private initializeConnectorFormGroup(): void {
    this.connectorFormGroup = this.formBuilder.group({
      connector_name: [getEmptyStringIfUnset(this.currentSSHModel?.connector_name), [Validators.required]],
    });
  }

  private getModel(): SSHModel | undefined {
    return this.currentSSHModel;
  }

  private initializeSSHNameFormGroup(sshModel: SSHModel): void {
    this.sshNameFormGroup = this.formBuilder.group({
      name: [
        getEmptyStringIfUnset(sshModel?.name),
        [Validators.required, this.customValidatorsService.resourceNameValidator()],
        [this.customValidatorsService.isDuplicateResourceName(this.orgId, this.sshModelStatus.complete)],
      ],
    });
  }

  private initializeAddressFormGroup(model: SSHModel): void {
    this.addressFormGroup = this.formBuilder.group({
      address: [getEmptyStringIfUnset(model?.address), [Validators.required, this.customValidatorsService.hostnameOrIP4Validator()]],
      port: [getEmptyStringIfUnset(model?.config.ports[0].port), [Validators.required, this.customValidatorsService.portValidator()]],
    });
  }

  private initializeOptionalConfigFormGroup(model: SSHModel): void {
    this.optionalConfigFormGroup = this.formBuilder.group({
      optional_config: [this.stepperState.optionalConfig, [Validators.required]],
      private_key: [getEmptyStringIfUnset(model?.secrets?.private_key), [this.customValidatorsService.sshCredentialPrivateKeyValidator()]],
      private_key_passphrase: [
        getEmptyStringIfUnset(model?.secrets?.private_key_passphrase),
        [this.customValidatorsService.sshCredentialPrivateKeyPassphraseValidator()],
      ],
      password: [getEmptyStringIfUnset(model?.secrets?.password), [this.customValidatorsService.sshCredentialPasswordValidator()]],
      username: getEmptyStringIfUnset(model?.secrets?.username),
    });
  }

  public hasAllPermissions(): boolean {
    return !!this.hasAppsPermissions && !!this.hasUsersPermissions && !!this.hasIssuersPermissions;
  }

  public showNoPermissionsText(): boolean {
    if (this.hasAppsPermissions === undefined || this.hasUsersPermissions === undefined || this.hasIssuersPermissions === undefined) {
      return false;
    }
    if (this.hasAllPermissions()) {
      return false;
    }
    return true;
  }

  public onFormBlur<T extends object>(form: FormGroup, formField: string, obj: T): void {
    modifyDataOnFormBlur(form, formField, this.modifyStepperDataOnFormBlur.bind(this), obj);
  }

  private modifyStepperDataOnFormBlur<T extends object>(form: FormGroup, formField: string, obj: T): void {
    const formValue = form.get(formField).value;
    obj[formField] = formValue;
    this.updateSSHModel();
  }

  public updateConnector(connectorName: string): void {
    this.currentSSHModel.connector_name = connectorName;
    this.updateSSHModel();
  }

  private setModelFromForms(): void {
    this.currentSSHModel.config.ports[0].port = this.addressFormGroup.get('port').value;
    this.currentSSHModel.secrets.private_key = this.optionalConfigFormGroup.get('private_key').value;
    this.currentSSHModel.secrets.private_key_passphrase = this.optionalConfigFormGroup.get('private_key_passphrase').value;
    this.currentSSHModel.secrets.password = this.optionalConfigFormGroup.get('password').value;
    this.currentSSHModel.secrets.username = this.optionalConfigFormGroup.get('username').value;
  }

  private updateSSHModel(): void {
    this.setModelFromForms();
  }

  public isStepperComplete(): boolean {
    return this.sshModelStatus.complete;
  }

  public submitSSHModel(sshModel: SSHModel): void {
    this.store.dispatch(new ActionApiApplicationsSubmittingSSHModel(sshModel));
  }

  public onOptionalConfigChange(isSelected: boolean): void {
    this.stepperState.optionalConfig = isSelected;
    if (!isSelected) {
      this.currentSSHModel.secrets.private_key = '';
      this.currentSSHModel.secrets.private_key_passphrase = '';
      this.currentSSHModel.secrets.password = '';
      this.optionalConfigFormGroup.get('private_key').setValue('');
      this.optionalConfigFormGroup.get('private_key_passphrase').setValue('');
      this.optionalConfigFormGroup.get('password').setValue('');
    }
  }

  public uploadPrivateKeyData(event: any): void {
    const file = getFile(event);
    if (!isValidPemFile(file)) {
      this.notificationService.error(getPemFileErrorMessage(file));
      return;
    }
    uploadDataFromFile(file, this.onReadPrivateKeyFile.bind(this));
  }

  private onReadPrivateKeyFile(reader: FileReader): void {
    this.optionalConfigFormGroup.get('private_key').setValue(reader.result.toString());
  }

  public getPrivateKeyValueFromForm(): string {
    return this.optionalConfigFormGroup.value.private_key;
  }

  public clearPrivateKey(): void {
    this.optionalConfigFormGroup.value.private_key = '';
  }

  public canDeactivate(): Observable<boolean> | boolean {
    this.resetModel();
    return true;
  }
}

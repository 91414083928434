import { Launcher, LauncherSpec, Resource, Application, ExtraProcess, LabelledObject } from '@agilicus/angular';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AppState } from '@app/core';
import { selectCanReadApps } from '@app/core/user/permissions/app.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
import { FilterManager } from '../filter/filter-manager';
import { getDefaultNestedDataProperties, getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import {
  Column,
  createCheckBoxColumn,
  createChipListColumn,
  createInputColumn,
  createSelectRowColumn,
  setColumnDefs,
  ChiplistColumn,
  createExpandColumn,
  createActionsColumn,
  ActionMenuOptions,
  IconColumn,
  createResourceIconColumn,
} from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { removeSurroundingQuotesFromString, setToUndefinedIfEmptyString, updateTableElements, getIconURIFromResource } from '../utils';
import { isValidLauncherName } from '../validation-utils';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import { ChiplistInput } from '../custom-chiplist-input/chiplist-input';
import { ResourceType } from '@app/shared/components/resource-type.enum';
import { getDefaultResourceMembersColumn, setResourceMapsAndColumnData } from '../resource-utils';
import {
  deletingLaunchers,
  initLaunchers,
  resetIconStatusLauncher,
  savingIconLauncher,
  savingLauncher,
} from '@app/core/launcher-state/launcher.actions';
import { ActionApiApplicationsInitApplications } from '@app/core/api-applications/api-applications.actions';
import {
  selectLauncherEntity,
  selectLauncherIconStatus,
  selectLauncherList,
  selectLauncherRefreshDataValue,
} from '@app/core/launcher-state/launcher.selectors';
import { selectApiApplicationsList, selectApiApplicationsRefreshData } from '@app/core/api-applications/api-applications.selectors';
import { cloneDeep } from 'lodash-es';
import {
  getForkThenAttachTooltipText,
  getLauncherApplicationsTooltipText,
  getLauncherCommandArgumentsTooltipText,
  getLauncherCommandPathTooltipText,
  getLauncherDescriptiveText,
  getLauncherDoInterceptTooltipText,
  getLauncherEndExistingIfRunningTooltipText,
  getLauncherExtraProcessAttachIfAlreadyRunningTooltipText,
  getLauncherExtraProcessCommandArgumentsTooltipText,
  getLauncherExtraProcessExitWhenEndingTooltipText,
  getLauncherExtraProcessForkThenAttachTooltipText,
  getLauncherExtraProcessNameRegexFlagTooltipText,
  getLauncherExtraProcessProgramNameTooltipText,
  getLauncherExtraProcessStartIfNotRunningTooltipText,
  getLauncherExtraProcessStartInTooltipText,
  getLauncherExtraProcessWaitForExitTooltipText,
  getLauncherHideConsoleTooltipText,
  getLauncherNameTooltipText,
  getLauncherProductGuideLink,
  getLauncherResourceMembersTooltipText,
  getLauncherRunAsAdminTooltipText,
  getLauncherStartInTooltipText,
} from '@app/core/launcher-state/launcher-utils';
import { RouterHelperService } from '@app/core/router-helper/router-helper.service';
import { OptionalLauncherElement } from '../optional-types';
import { AuditRoute } from '../audit-subsystem/audit-subsystem.component';
import { ButtonType } from '../button-type.enum';
import { InputData } from '../custom-chiplist-input/input-data';
import { ResourceLogoDialogComponent, ResourceLogoDialogData } from '../resource-logo-dialog/resource-logo-dialog.component';
import { getDefaultLogoDialogConfig } from '../dialog-utils';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { initPolicyTemplateInstances } from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import {
  PolicyTemplateInstanceWithLabels,
  ResourceTableElement,
} from '@app/core/api/policy-template-instance/policy-template-instance-utils';
import { PolicyResourceLinkService } from '@app/core/policy-resource-link-service/policy-resource-link.service';
import { FeatureGateService } from '@app/core/services/feature-gate.service';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';
import { Router } from '@angular/router';
import { InputSize } from '../custom-chiplist-input/input-size.enum';

export interface LauncherElement extends ResourceTableElement, LauncherSpec {
  diagnostic_mode: boolean;
  command_path?: string;
  command_arguments?: string;
  start_in?: string;
  do_intercept?: boolean;
  hide_console?: boolean;
  run_as_admin?: boolean;
  fork_then_attach?: boolean;
  end_existing_if_running?: boolean;
  backingObject: Launcher;
}

export interface ExtraProcessElement extends ExtraProcess, TableElement {
  parentId?: string | number;
  backingObject?: ExtraProcess;
}

export interface CombinedPermissionsAndData {
  permission: OrgQualifiedPermission;
  launchers: Array<Launcher>;
  resources: Array<Resource>;
  applications: Array<Application>;
}

@Component({
  selector: 'portal-launcher-overview',
  templateUrl: './launcher-overview.component.html',
  styleUrls: ['./launcher-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LauncherOverviewComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hasAppsPermissions: boolean;
  public hasResourcePermissions: boolean;
  public hasPermissions: boolean;
  private orgId: string;
  public tableData: Array<LauncherElement> = [];
  public columnDefs: Map<string, Column<LauncherElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public rowObjectName = 'LAUNCHER';
  public buttonsToShow: Array<ButtonType> = [ButtonType.DELETE];
  public customButtons: Array<TableButton> = [
    new TableScopedButton(
      `ADD ${this.rowObjectName}`,
      ButtonColor.PRIMARY,
      `Add a new ${this.rowObjectName.toLowerCase()}`,
      `Button that adds a new ${this.rowObjectName.toLowerCase()}`,
      () => {
        this.router.navigate(['/launcher-new'], {
          queryParams: { org_id: this.orgId },
        });
      }
    ),
  ];
  private resourceIdToResourceMap: Map<string, Resource> = new Map();
  private resourceNameAndTypeToResourceMap: Map<string, Resource> = new Map();
  private applicationIdToApplicationMap: Map<string, Application> = new Map();
  private applicationNameToApplicationMap: Map<string, Application> = new Map();
  public pageDescriptiveText = getLauncherDescriptiveText();
  public productGuideLink = getLauncherProductGuideLink();
  private localLauncherRefreshDataValue = 0;
  private localApplicationRefreshDataValue = 0;
  // For policy column:
  private policyTemplateInstanceList: Array<PolicyTemplateInstanceWithLabels> = [];
  // For policy column:
  private resources: Array<Resource> = [];
  // For policy column:
  private labelledObjects: Array<LabelledObject> = [];
  // For policy column:
  private policyNameAndTypeStringToPolicyMap: Map<string, PolicyTemplateInstanceWithLabels> = new Map();
  // For policy column:
  private localPoliciesRefreshDataValue = 0;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private routerHelperService: RouterHelperService,
    public dialog: MatDialog,
    private router: Router,
    private policyResourceLinkService: PolicyResourceLinkService<LauncherElement>,
    private featureGateService: FeatureGateService
  ) {}

  public shouldGateAccess(): boolean {
    return !this.featureGateService.shouldEnablePolicyTemplates();
  }

  public ngOnInit(): void {
    this.store.dispatch(initLaunchers({ force: true, blankSlate: false }));
    this.store.dispatch(new ActionApiApplicationsInitApplications(true, false, false));
    // For policy column:
    this.store.dispatch(initPolicyTemplateInstances({ force: true, blankSlate: false }));
    this.initializeColumnDefs();
    const hasAppsPermissions$ = this.store.pipe(select(selectCanReadApps));
    const launcherListState$ = this.store.pipe(select(selectLauncherList));
    const applicationsListState$ = this.store.pipe(select(selectApiApplicationsList));
    const refreshLauncherDataState$ = this.store.pipe(select(selectLauncherRefreshDataValue));
    const refreshApplicationDataState$ = this.store.pipe(select(selectApiApplicationsRefreshData));
    combineLatest([
      hasAppsPermissions$,
      this.policyResourceLinkService.getPolicyAndResourceAndLabelDataWithPermissions$(),
      launcherListState$,
      applicationsListState$,
      refreshLauncherDataState$,
      refreshApplicationDataState$,
    ])
      .pipe(
        concatMap(
          ([
            hasAppsPermissionsResp,
            getPermissionsAndResourceAndLabelDataResp,
            launcherListStateResp,
            applicationsListStateResp,
            refreshLauncherDataStateResp,
            refreshApplicationDataStateResp,
          ]) => {
            return combineLatest([
              of(hasAppsPermissionsResp),
              of(getPermissionsAndResourceAndLabelDataResp),
              of(launcherListStateResp),
              of(applicationsListStateResp),
              of(refreshLauncherDataStateResp),
              of(refreshApplicationDataStateResp),
            ]);
          }
        )
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          hasAppsPermissionsResp,
          getPermissionsAndResourceAndLabelDataResp,
          launcherListStateResp,
          applicationsListStateResp,
          refreshLauncherDataStateResp,
          refreshApplicationDataStateResp,
        ]) => {
          this.orgId = hasAppsPermissionsResp?.orgId;
          this.hasAppsPermissions = hasAppsPermissionsResp.hasPermission;
          this.hasResourcePermissions = getPermissionsAndResourceAndLabelDataResp.hasResourcePermissions.hasPermission;
          const hasPermissions =
            hasAppsPermissionsResp.hasPermission && getPermissionsAndResourceAndLabelDataResp.hasResourcePermissions.hasPermission;
          // For policy column:
          this.policyTemplateInstanceList = getPermissionsAndResourceAndLabelDataResp.policyTemplateInstanceList;
          // For policy column:
          this.resources = getPermissionsAndResourceAndLabelDataResp.resourcesList;
          // For policy column:
          this.labelledObjects = getPermissionsAndResourceAndLabelDataResp.labelledObjects;
          if (
            !hasPermissions ||
            !launcherListStateResp ||
            !applicationsListStateResp ||
            !getPermissionsAndResourceAndLabelDataResp.resourcesList
          ) {
            this.resetEmptyTable();
            return;
          }
          setResourceMapsAndColumnData(
            this.resourceIdToResourceMap,
            this.resourceNameAndTypeToResourceMap,
            getPermissionsAndResourceAndLabelDataResp.resourcesList,
            this.columnDefs
          );
          this.setApplicationMaps(applicationsListStateResp);
          // For policy column:
          this.policyResourceLinkService.setPolicyNameAndTypeStringMap(
            this.policyNameAndTypeStringToPolicyMap,
            this.policyTemplateInstanceList
          );
          // For policy column:
          this.policyResourceLinkService.setResourceTablePoliciesColumnAllowedValuesList(this.columnDefs, this.policyTemplateInstanceList);
          if (
            this.tableData.length === 0 ||
            this.localLauncherRefreshDataValue !== refreshLauncherDataStateResp ||
            this.localApplicationRefreshDataValue !== refreshApplicationDataStateResp
          ) {
            this.localLauncherRefreshDataValue = refreshLauncherDataStateResp;
            this.localApplicationRefreshDataValue = refreshApplicationDataStateResp;
            this.localPoliciesRefreshDataValue = getPermissionsAndResourceAndLabelDataResp.refreshPolicyTemplateInstanceDataStateValue;
            if (
              this.localLauncherRefreshDataValue !== 0 &&
              this.localApplicationRefreshDataValue !== 0 &&
              this.localPoliciesRefreshDataValue !== 0
            ) {
              // Only render the table data once all fresh data is retrieved from the ngrx state.
              // Once each state is updated the local refresh values will have incremented by at least 1.
              this.updateTable(launcherListStateResp);
            }
          }
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setApplicationMaps(applicationsList: Array<Application>): void {
    this.applicationIdToApplicationMap.clear();
    this.applicationNameToApplicationMap.clear();
    this.columnDefs.get('applications').allowedValues.length = 0;
    for (const application of applicationsList) {
      this.applicationIdToApplicationMap.set(application.id, application);
      this.applicationNameToApplicationMap.set(application.name, application);
      this.columnDefs.get('applications').allowedValues.push(application);
    }
  }

  /**
   * Parent table column
   */
  private getIconColumn(): IconColumn<LauncherElement> {
    return createResourceIconColumn<InputData, LauncherElement>(this.getIconURIFromElement.bind(this), this.openIconDialog.bind(this));
  }

  private getIconURIFromElement(element: LauncherElement): string {
    return getIconURIFromResource(element.backingObject);
  }

  public openIconDialog(element: LauncherElement): void {
    const dialogData: ResourceLogoDialogData<Launcher> = {
      resourceType: ResourceType.launcher,
      saveAction: savingIconLauncher,
      resetStatusAction: resetIconStatusLauncher,
      getResourceStateSelector: selectLauncherEntity({ id: element.backingObject.metadata.id }),
      getIconStatusStateSelector: selectLauncherIconStatus,
    };
    const dialogRef = this.dialog.open(
      ResourceLogoDialogComponent,
      getDefaultLogoDialogConfig({
        data: dialogData,
      })
    );
  }

  /**
   * Parent Table Column
   */
  private getNameColumn(): Column<LauncherElement> {
    const nameColumn = createInputColumn('name');
    nameColumn.requiredField = () => true;
    nameColumn.isEditable = true;
    nameColumn.isValidEntry = (name: string, element: LauncherElement, column: Column<LauncherElement>): boolean => {
      if (element.backingObject.spec.name === name) {
        // We do not want to prevent updates to existing networks
        // that do not meet the new requirements for naming.
        // So, if the value has not been changed, do not apply validation logic.
        return true;
      }
      return isValidLauncherName(name);
    };
    nameColumn.getHeaderTooltip = (): string => {
      return getLauncherNameTooltipText();
    };
    return nameColumn;
  }

  /**
   * Parent Table Column
   */
  private getCommandPathColumn(): Column<LauncherElement> {
    const column = createInputColumn('command_path');
    // This is required in the stepper but not here
    column.requiredField = () => false;
    column.isEditable = true;
    column.getHeaderTooltip = () => {
      return getLauncherCommandPathTooltipText();
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getCommandArgumentsColumn(): Column<LauncherElement> {
    const commandArgumentsColumn = createInputColumn('command_arguments');
    commandArgumentsColumn.isEditable = true;
    commandArgumentsColumn.getHeaderTooltip = () => {
      return getLauncherCommandArgumentsTooltipText();
    };
    return commandArgumentsColumn;
  }

  /**
   * Parent Table Column
   */
  private getResourceMembersColumn(): Column<LauncherElement> {
    const column = getDefaultResourceMembersColumn<LauncherElement>(this.resourceIdToResourceMap, this.resourceNameAndTypeToResourceMap);
    column.getHeaderTooltip = () => {
      return getLauncherResourceMembersTooltipText();
    };
    column.inputSize = InputSize.STANDARD;
    return column;
  }

  /**
   * Parent Table Column
   */
  private getApplicationsColumn(): ChiplistColumn<LauncherElement> {
    const column = createChipListColumn('applications');
    // Hide this column by default:
    column.showColumn = false;
    column.displayName = 'Applications';
    column.getDisplayValue = (element: any) => {
      let targetResource = this.applicationIdToApplicationMap.get(element.id);
      if (!targetResource) {
        targetResource = this.applicationIdToApplicationMap.get(element);
      }
      return targetResource?.name ? targetResource?.name : '';
    };
    column.getElementFromValue = (applicationName: string): any => {
      return this.applicationNameToApplicationMap.get(applicationName).id;
    };
    column.getHeaderTooltip = () => {
      return getLauncherApplicationsTooltipText();
    };
    column.inputSize = InputSize.STANDARD;
    return column;
  }

  /**
   * Parent Table Column
   */
  private getPoliciesColumn(): ChiplistColumn<LauncherElement> {
    return this.policyResourceLinkService.getResourceTablePoliciesColumn(this.policyNameAndTypeStringToPolicyMap);
  }

  /**
   * Parent Table Column
   */
  private getDiagnosticModeColumn(): Column<LauncherElement> {
    const column = createCheckBoxColumn('diagnostic_mode');
    column.getHeaderTooltip = () => {
      return `If enabled, the launcher will run in diagnostic mode. It will keep a terminal window open and set the log level to debug.`;
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getActionsColumn(): Column<LauncherElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<LauncherElement>> = [
      {
        displayName: 'Search in Audits',
        icon: 'search',
        tooltip: 'Click to filter audits',
        onClick: (element: OptionalLauncherElement) => {
          const auditRouteData: AuditRoute = {
            org_id: element.backingObject.spec.org_id,
            target_resource_id: element.backingObject.metadata.id,
            target_resource_name: element.name,
          };
          this.routerHelperService.redirect('audit-subsystem/', auditRouteData);
        },
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  /**
   * Nested Form Column
   */
  private getStartInColumn(): Column<LauncherElement> {
    const startInColumn = createInputColumn('start_in');
    startInColumn.displayName = 'Start directory';
    startInColumn.isEditable = true;
    startInColumn.getHeaderTooltip = () => {
      return getLauncherStartInTooltipText();
    };
    return startInColumn;
  }

  /**
   * Nested Form Column
   */
  private getDoInterceptColumn(): Column<LauncherElement> {
    const doInterceptColumn = createCheckBoxColumn('do_intercept');
    doInterceptColumn.displayName = 'Requires interceptor';
    doInterceptColumn.isEditable = true;
    doInterceptColumn.getHeaderTooltip = () => {
      return getLauncherDoInterceptTooltipText();
    };
    return doInterceptColumn;
  }

  /**
   * Nested Form Column
   */
  private getHideConsoleColumn(): Column<LauncherElement> {
    const hideConsoleColumn = createCheckBoxColumn('hide_console');
    hideConsoleColumn.isEditable = true;
    hideConsoleColumn.getHeaderTooltip = () => {
      return getLauncherHideConsoleTooltipText();
    };
    return hideConsoleColumn;
  }

  /**
   * Nested Form Column
   */
  private getRunAsAdminColumn(): Column<LauncherElement> {
    const runAsAdminColumn = createCheckBoxColumn('run_as_admin');
    runAsAdminColumn.displayName = 'Run with elevated privileges';
    runAsAdminColumn.isEditable = true;
    runAsAdminColumn.getHeaderTooltip = () => {
      return getLauncherRunAsAdminTooltipText();
    };
    return runAsAdminColumn;
  }

  /**
   * Nested Form Column
   */
  private getForkThenAttachColumn(): Column<LauncherElement> {
    const forkThenAttachColumn = createCheckBoxColumn('fork_then_attach');
    forkThenAttachColumn.displayName = 'Requires custom, delayed initialisation';
    forkThenAttachColumn.isEditable = true;
    forkThenAttachColumn.getHeaderTooltip = () => {
      return getForkThenAttachTooltipText();
    };
    return forkThenAttachColumn;
  }

  /**
   * Nested Form Column
   */
  private getEndExistingIfRunningColumn(): Column<LauncherElement> {
    const column = createCheckBoxColumn('end_existing_if_running');
    column.displayName = 'On launcher start, terminate the process if already running';
    column.isEditable = true;
    column.getHeaderTooltip = () => {
      return getLauncherEndExistingIfRunningTooltipText();
    };
    return column;
  }

  /**
   * Nested Table Column
   */
  private getProgramNameColumn(): Column<ExtraProcessElement> {
    const column = createInputColumn('program_name');
    column.isEditable = true;
    column.getHeaderTooltip = () => {
      return getLauncherExtraProcessProgramNameTooltipText();
    };
    return column;
  }

  /**
   * Nested Table Column
   */
  private getExtraProcessCommandArgumentsColumn(): Column<ExtraProcessElement> {
    const column = createInputColumn('command_arguments');
    column.isEditable = true;
    column.getHeaderTooltip = () => {
      return getLauncherExtraProcessCommandArgumentsTooltipText();
    };
    return column;
  }

  /**
   * Nested Table Column
   */
  private getExtraProcessStartInColumn(): Column<ExtraProcessElement> {
    const column = createInputColumn('start_in');
    column.displayName = 'Start directory';
    column.isEditable = true;
    column.getHeaderTooltip = () => {
      return getLauncherExtraProcessStartInTooltipText();
    };
    return column;
  }

  /**
   * Nested Table Column
   */
  private getNameRegexFlagColumn(): Column<ExtraProcessElement> {
    const column = createCheckBoxColumn('name_regex_flag');
    column.displayName = 'Name is regex';
    column.getHeaderTooltip = () => {
      return getLauncherExtraProcessNameRegexFlagTooltipText();
    };
    return column;
  }

  /**
   * Nested Table Column
   */
  private getStartIfNotRunningColumn(): Column<ExtraProcessElement> {
    const column = createCheckBoxColumn('start_if_not_running');
    column.getHeaderTooltip = () => {
      return getLauncherExtraProcessStartIfNotRunningTooltipText();
    };
    return column;
  }

  /**
   * Nested Table Column
   */
  private getExitWhenEndingColumn(): Column<ExtraProcessElement> {
    const column = createCheckBoxColumn('exit_when_ending');
    column.getHeaderTooltip = () => {
      return getLauncherExtraProcessExitWhenEndingTooltipText();
    };
    return column;
  }

  /**
   * Nested Table Column
   */
  private getAttachIfAlreadyRunningColumn(): Column<ExtraProcessElement> {
    const column = createCheckBoxColumn('attach_if_already_running');
    column.getHeaderTooltip = () => {
      return getLauncherExtraProcessAttachIfAlreadyRunningTooltipText();
    };
    return column;
  }

  /**
   * Nested Table Column
   */
  private getExtraProcessForkThenAttachColumn(): Column<ExtraProcessElement> {
    const column = createCheckBoxColumn('fork_then_attach');
    column.getHeaderTooltip = () => {
      return getLauncherExtraProcessForkThenAttachTooltipText();
    };
    return column;
  }

  /**
   * Nested Table Column
   */
  private getExtraProcessWaitForExitColumn(): Column<ExtraProcessElement> {
    const column = createCheckBoxColumn('wait_for_exit');
    column.getHeaderTooltip = () => {
      return getLauncherExtraProcessWaitForExitTooltipText();
    };
    return column;
  }

  /**
   * Parent Table ColumnDefs
   */
  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getIconColumn(),
        this.getNameColumn(),
        this.getCommandPathColumn(),
        this.getCommandArgumentsColumn(),
        this.getResourceMembersColumn(),
        this.getApplicationsColumn(),
        // TODO: add policy column when we add policies that apply to launchers:
        // this.getPoliciesColumn(),
        this.getDiagnosticModeColumn(),
        this.getActionsColumn(),
        createExpandColumn<LauncherElement>(),
      ],
      this.columnDefs
    );
  }

  /**
   * Nested Table ColumnDefs
   */
  private initializeNestedColumnDefs(nestedColumnDefs: Map<string, Column<ExtraProcessElement>>): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getProgramNameColumn(),
        this.getExtraProcessCommandArgumentsColumn(),
        this.getExtraProcessStartInColumn(),
        this.getNameRegexFlagColumn(),
        this.getStartIfNotRunningColumn(),
        this.getExitWhenEndingColumn(),
        this.getAttachIfAlreadyRunningColumn(),
        this.getExtraProcessForkThenAttachColumn(),
        this.getExtraProcessWaitForExitColumn(),
      ],
      nestedColumnDefs
    );
  }

  private updateTable(data: Array<Launcher>): void {
    this.buildData(data);
    this.replaceTableWithCopy();
  }

  private buildData(data: Array<Launcher>): void {
    const dataForTable: Array<LauncherElement> = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      dataForTable.push(this.createTableElement(item, i));
    }
    this.setNestedTableData(dataForTable);
    updateTableElements(this.tableData, dataForTable);
  }

  private createTableElement(item: Launcher, index: number): LauncherElement {
    if (item.spec.config === undefined) {
      item.spec.config = {};
    }
    const data: LauncherElement = {
      name: item.spec?.name,
      org_id: item.spec?.org_id,
      resource_members: item.spec?.resource_members,
      config: item.spec?.config,
      command_path: item.spec?.config?.command_path,
      command_arguments: item.spec?.config?.command_arguments,
      start_in: item.spec?.config?.start_in,
      do_intercept: item.spec?.config?.do_intercept,
      hide_console: item.spec?.config?.hide_console,
      run_as_admin: item.spec?.config?.run_as_admin,
      end_existing_if_running: item.spec?.config?.end_existing_if_running,
      fork_then_attach: item.spec?.config?.interceptor_config?.fork_then_attach,
      diagnostic_mode: !!item.spec?.alternate_mode_setting?.diagnostic_mode,
      backingObject: cloneDeep(item),
      applications: item.spec?.applications,
      nestedFormColumnDefs: new Map(),
      ...getDefaultTableProperties(index),
      ...this.policyResourceLinkService.getResourceElementWithPolicies(
        item,
        this.resources,
        this.labelledObjects,
        this.policyTemplateInstanceList
      ),
    };
    setColumnDefs(
      [
        this.getStartInColumn(),
        this.getDoInterceptColumn(),
        this.getHideConsoleColumn(),
        this.getRunAsAdminColumn(),
        this.getForkThenAttachColumn(),
        this.getEndExistingIfRunningColumn(),
      ],
      data.nestedFormColumnDefs
    );
    return data;
  }

  private createExtraProcessElement(extraProcess: ExtraProcess, index: number, element: LauncherElement): ExtraProcessElement {
    const data: ExtraProcessElement = {
      ...extraProcess,
      parentId: element.index,
      backingObject: extraProcess,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  private setNestedTableData(data: Array<LauncherElement>): void {
    for (const element of data) {
      element.expandedData = {
        ...getDefaultNestedDataProperties(element),
        nestedRowObjectName: 'PROCESS',
        nestedButtonsToShow: [ButtonType.ADD, ButtonType.DELETE],
        makeEmptyNestedTableElement: (): ExtraProcessElement => {
          return {
            program_name: '',
            name_regex_flag: false,
            start_if_not_running: false,
            exit_when_ending: false,
            attach_if_already_running: false,
            fork_then_attach: false,
            wait_for_exit: false,
            command_arguments: undefined,
            start_in: undefined,
            parentId: element.index,
            backingObject: {
              program_name: '',
              name_regex_flag: false,
              start_if_not_running: false,
              exit_when_ending: false,
              attach_if_already_running: false,
              fork_then_attach: false,
              wait_for_exit: false,
              command_arguments: undefined,
              start_in: undefined,
            },
            ...getDefaultNewRowProperties(),
          };
        },
      };
      this.initializeNestedColumnDefs(element.expandedData.nestedColumnDefs);
      for (let i = 0; i < element.config?.extra_processes?.length; i++) {
        const extraProcess = element.config.extra_processes[i];
        const nestedElement = this.createExtraProcessElement(extraProcess, i, element);
        element.expandedData.nestedTableData.push(nestedElement);
      }
    }
  }

  /**
   * Receives an element from the table then updates and saves
   * the data.
   */
  public updateEvent(updatedElement: LauncherElement | ExtraProcessElement): void {
    const updatedElementAsExtraProcess = updatedElement as ExtraProcessElement;
    if (updatedElementAsExtraProcess.parentId !== undefined && updatedElementAsExtraProcess.parentId !== null) {
      const parentElement = this.getParentElementFromParentId(updatedElementAsExtraProcess);
      const updatedExtraProcesses = this.getExtraProcessesFromNestedTableData(parentElement.expandedData.nestedTableData);
      parentElement.backingObject.spec.config.extra_processes = updatedExtraProcesses;
      this.saveItem(parentElement);
      return;
    }
    this.saveItem(updatedElement as LauncherElement);
  }

  private getParentElementFromParentId(nestedElement: ExtraProcessElement): LauncherElement | undefined {
    return this.tableData[nestedElement.parentId];
  }

  private getExtraProcessesFromNestedTableData(nestedTableData: Array<ExtraProcessElement>): Array<ExtraProcess> {
    const data: Array<ExtraProcess> = [];
    for (const element of nestedTableData) {
      data.push(this.getExtraProcessFromExtraProcessElement(element));
    }
    return data;
  }

  private getExtraProcessFromExtraProcessElement(nestedElement: ExtraProcessElement): ExtraProcess {
    const result: ExtraProcess = {
      program_name: nestedElement.program_name,
      name_regex_flag: nestedElement.name_regex_flag,
      start_if_not_running: nestedElement.start_if_not_running,
      exit_when_ending: nestedElement.exit_when_ending,
      attach_if_already_running: nestedElement.attach_if_already_running,
      fork_then_attach: nestedElement.fork_then_attach,
      wait_for_exit: nestedElement.wait_for_exit,
      command_arguments: setToUndefinedIfEmptyString(nestedElement.command_arguments),
      start_in: setToUndefinedIfEmptyString(nestedElement.start_in),
    };
    return result;
  }

  private getFormattedCommandPath(tableElement: LauncherElement): string | undefined {
    return tableElement.command_path === undefined ? undefined : removeSurroundingQuotesFromString(tableElement.command_path).trim();
  }

  private getItemFromTableElement(tableElement: LauncherElement): Launcher {
    const result: Launcher = tableElement.backingObject;
    result.spec.name = tableElement.name;
    result.spec.resource_members = tableElement.resource_members;
    result.spec.applications = tableElement.applications;
    const formattedCommandPath = this.getFormattedCommandPath(tableElement);
    tableElement.command_path = formattedCommandPath;
    if (!result.spec.config) {
      result.spec.config = {
        command_path: tableElement.command_path,
        command_arguments: tableElement.command_arguments,
        start_in: tableElement.start_in,
        do_intercept: tableElement.do_intercept,
        hide_console: tableElement.hide_console,
        run_as_admin: tableElement.run_as_admin,
        end_existing_if_running: tableElement.end_existing_if_running,
        interceptor_config: {
          fork_then_attach: tableElement.fork_then_attach,
        },
      };
      return result;
    }
    result.spec.config.command_path = tableElement.command_path;
    result.spec.config.command_arguments = tableElement.command_arguments;
    result.spec.config.start_in = tableElement.start_in;
    result.spec.config.do_intercept = tableElement.do_intercept;
    result.spec.config.hide_console = tableElement.hide_console;
    result.spec.config.run_as_admin = tableElement.run_as_admin;
    result.spec.config.end_existing_if_running = tableElement.end_existing_if_running;
    if (!result.spec.config.interceptor_config) {
      result.spec.config.interceptor_config = {
        fork_then_attach: tableElement.fork_then_attach,
      };
    } else {
      result.spec.config.interceptor_config.fork_then_attach = tableElement.fork_then_attach;
    }
    if (!result.spec.alternate_mode_setting) {
      result.spec.alternate_mode_setting = {
        diagnostic_mode: tableElement.diagnostic_mode,
      };
    } else {
      result.spec.alternate_mode_setting.diagnostic_mode = tableElement.diagnostic_mode;
    }
    return result;
  }

  private saveItem(tableElement: LauncherElement, refreshData = false): void {
    const updatedItem = this.getItemFromTableElement(tableElement);
    this.policyResourceLinkService
      .submitPoliciesResult$(tableElement, this.orgId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resourceAndLabelsAndPolicyResponse) => {
        this.policyResourceLinkService.onSubmitPoliciesFinish(tableElement, resourceAndLabelsAndPolicyResponse);
        // Need to make a copy of the object or it will be converted to readonly.
        this.store.dispatch(
          savingLauncher({ obj: cloneDeep(updatedItem), trigger_update_side_effects: false, notifyUser: true, refreshData })
        );
        this.changeDetector.detectChanges();
      });
  }

  public getItemsFromTableElements(tableElements: Array<LauncherElement>): Array<Launcher> {
    return tableElements.map((element) => element.backingObject);
  }

  public deleteSelected(itemsToDelete: Array<LauncherElement | ExtraProcessElement>): void {
    const elementAsExtraProcessElement = itemsToDelete[0] as ExtraProcessElement;
    if (elementAsExtraProcessElement.parentId !== undefined && elementAsExtraProcessElement.parentId !== null) {
      this.deleteFromNestedTable(elementAsExtraProcessElement);
      return;
    }
    const elementsAsLauncherElement = itemsToDelete as Array<LauncherElement>;
    this.deleteFromParentTable(elementsAsLauncherElement);
  }

  private deleteFromNestedTable(nestedElement: ExtraProcessElement): void {
    const parentElement = this.getParentElementFromParentId(nestedElement);
    parentElement.expandedData.nestedTableData = parentElement.expandedData.nestedTableData.filter((element) => !element.isChecked);
    parentElement.backingObject.spec.config.extra_processes = this.getExtraProcessesFromNestedTableData(
      parentElement.expandedData.nestedTableData
    );
    this.saveItem(parentElement);
  }

  private deleteFromParentTable(itemsToDelete: Array<LauncherElement>): void {
    const launchersToDelete: Array<Launcher> = this.getItemsFromTableElements(itemsToDelete);
    // Need to make a copy of the object or it will be converted to readonly.
    this.store.dispatch(deletingLaunchers({ objs: cloneDeep(launchersToDelete), trigger_update_side_effects: false, notifyUser: true }));
  }

  public hasAllPermissions(): boolean {
    return this.hasAppsPermissions && this.hasResourcePermissions;
  }

  public showNoPermissionsText(): boolean {
    return this.hasAppsPermissions !== undefined && this.hasResourcePermissions !== undefined && !this.hasAllPermissions();
  }

  public removeFromAllowedValues(element: LauncherElement, chiplistInput: ChiplistInput<LauncherElement>, optionValue: string): boolean {
    if (chiplistInput.name === 'resource_members' && optionValue.indexOf('(') >= 0) {
      const resourceType = optionValue.substring(optionValue.indexOf('(') + 1, optionValue.indexOf(')'));
      const resourceName = optionValue.substring(0, optionValue.indexOf('('));
      if (resourceType === ResourceType.launcher && resourceName === element.name) {
        return true;
      }
    }
    return false;
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public preventDeleteOnEnter(event: any): void {
    event.preventDefault();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}

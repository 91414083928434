import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FaqItem } from '@app/core/models/faq.model';
import { FaqService } from '@app/core/services/faq/faq.service';
import fuse from 'fuse.js';
import { Subject, takeUntil } from 'rxjs';
import { capitalizeFirstLetter, getConvertedTextFromHtml, replaceCharacterWithSpace } from '../utils';
import { getProductLinkViaLocalStorage } from '@app/shared/components/product-guide-link/product-guide-link.utils';

@Component({
  selector: 'portal-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent implements OnInit, OnDestroy {
  @Input() public faqData: Array<FaqItem>;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public faqAllDataLocalCache: Array<FaqItem>;
  public fuseObject: fuse<FaqItem>;
  public faqSearchForm: UntypedFormGroup;
  public faqSearchResults: Array<FaqItem>;
  public faqCategoryToFaqItemListMap: Map<string, Array<FaqItem>> = new Map();
  public isLoading = false;
  private categoriesToExclude = ['starlink'];

  public capitalizeFirstLetter = capitalizeFirstLetter;

  constructor(private faqService: FaqService, private formBuilder: UntypedFormBuilder, private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initializeFaqSearchFormGroup();
    this.getAndSetAllData();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getAndSetAllData(): void {
    const storedFaqData = this.faqService.getFaqDataFromSessionStorage();
    if (!storedFaqData) {
      this.isLoading = true;
      const faqData$ = this.faqService.getAllItemsAsObservableArray$(this.categoriesToExclude);
      faqData$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
        sessionStorage.setItem('faqData', JSON.stringify(resp));
        this.setLocalData(resp);
      });
    } else {
      this.setLocalData(storedFaqData);
    }
  }

  private setLocalData(faqItemList: Array<FaqItem>): void {
    this.isLoading = false;
    this.faqAllDataLocalCache = faqItemList;
    this.faqSearchResults = faqItemList;
    this.setFaqCategoryMap();
    this.setFuseObject();
    this.changeDetector.detectChanges();
  }

  private initializeFaqSearchFormGroup(): void {
    this.faqSearchForm = this.formBuilder.group({
      faq_search: '',
    });
  }

  public getFaqSearchFormValue(): string {
    return this.faqSearchForm.get('faq_search').value;
  }

  private setFuseObject(): void {
    this.fuseObject = new fuse(this.faqAllDataLocalCache, {
      keys: ['title.rendered', 'slug', 'link', 'excerpt.rendered', 'content.rendered'],
    });
  }

  /**
   * Triggers getting the faq results when an action such as a button click
   * or enter keypress occurs.
   */
  public getFaqDataOnAction(): void {
    this.setFaqSearchResults();
  }

  public setFaqSearchResults() {
    const faqKeyWord = this.getFaqSearchFormValue();
    if (!faqKeyWord) {
      this.faqSearchResults = this.faqAllDataLocalCache;
    } else {
      this.faqSearchResults = this.fuseObject.search(faqKeyWord).map((value) => value.item);
    }
    this.setFaqCategoryMap();
  }

  private getCategoryListFromFaqItem(faqItem: FaqItem): Array<string> | undefined {
    const categoryList: Array<string> = [];
    for (const category of faqItem.categories) {
      categoryList.push(category);
    }
    return categoryList;
  }

  public getFormattedCategoryName(categoryName: string): string {
    if (!categoryName) {
      return undefined;
    }
    return replaceCharacterWithSpace(categoryName, '-');
  }

  private setFaqCategoryMap(): void {
    this.faqCategoryToFaqItemListMap.clear();
    for (const faqItem of this.faqSearchResults) {
      const categoryList = this.getCategoryListFromFaqItem(faqItem);
      faqItem.link = getProductLinkViaLocalStorage(faqItem.link);
      for (const category of categoryList) {
        const existingMapEntry = this.faqCategoryToFaqItemListMap.get(category);
        if (!existingMapEntry) {
          this.faqCategoryToFaqItemListMap.set(category, [faqItem]);
        } else {
          existingMapEntry.push(faqItem);
          this.faqCategoryToFaqItemListMap.set(category, existingMapEntry);
        }
      }
    }
  }

  public getCategoryNamesListAsArray(): Array<string> {
    return Array.from(this.faqCategoryToFaqItemListMap.keys());
  }

  public getFaqItemsByCategory(category: string): Array<FaqItem> {
    return this.faqCategoryToFaqItemListMap.get(category);
  }

  public getFormattedFaqTitle(faqItem: FaqItem): string {
    return capitalizeFirstLetter(getConvertedTextFromHtml(faqItem.title.rendered));
  }

  public getFormattedFaqExcerpt(faqItem: FaqItem): string {
    return getConvertedTextFromHtml(faqItem.excerpt.rendered);
  }

  public isFaqItemOpen(faqItem: FaqItem): boolean {
    return !!faqItem.open;
  }

  public onFaqItemToggle(faqItem: FaqItem): void {
    faqItem.open = !this.isFaqItemOpen(faqItem);
  }
}

import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import {
  AuditDestination,
  AuditsService,
  ListAuditDestinationsRequestParams,
  AuditDestinationSpec,
  CreateAuditDestinationRequestParams,
  GetAuditDestinationRequestParams,
  ReplaceAuditDestinationRequestParams,
  patch_via_put,
  AuditDestinationAuthentication,
  AuditDestinationFilter,
} from '@agilicus/angular';
import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import { takeUntil, concatMap, map, catchError } from 'rxjs/operators';
import { updateTableElements, createEnumChecker } from '@app/shared/components/utils';
import { FilterManager } from '../filter/filter-manager';
import { Store, select } from '@ngrx/store';
import { AppState, NotificationService } from '@app/core';
import { Subject } from 'rxjs';
import { selectCanAdminAuditDestinations } from '@app/core/user/permissions/audit-destinations.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import {
  Column,
  createInputColumn,
  setColumnDefs,
  createSelectRowColumn,
  createSelectColumn,
  createCheckBoxColumn,
} from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { AppErrorHandler } from '@app/core/error-handler/app-error-handler.service';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import { ButtonType } from '../button-type.enum';
import { AuditRecordAccessType } from '../audit-record-access-types.enum';
import { capitalizeFirstLetter, replaceCharacterWithSpace } from '../utils';
import { OptionalAuditDestinationElement } from '../optional-types';
import { sortArrayByMetadataCreatedDate } from '../date-utils';

export interface AuditDestinationElement extends AuditDestinationSpec, TableElement {
  backingObject?: AuditDestination;
  authentication_type?: AuditDestinationAuthentication.AuthenticationTypeEnum;
  username?: string;
  password?: string;
  token?: string;
  access?: boolean;
  authorization?: boolean;
}

export interface CombinedPermissionsAndAuditDestinationData {
  permission: OrgQualifiedPermission;
  auditDestinations: Array<AuditDestination>;
}

@Component({
  selector: 'portal-audit-destinations',
  templateUrl: './audit-destinations.component.html',
  styleUrls: ['./audit-destinations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditDestinationsComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  public columnDefs: Map<string, Column<AuditDestinationElement>> = new Map();
  public tableData: Array<AuditDestinationElement> = [];
  public filterManager: FilterManager = new FilterManager();
  public rowObjectName = 'DESTINATION';
  public buttonsToShow: Array<string> = [ButtonType.ADD, ButtonType.DELETE];
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  private permissions$: Observable<OrgQualifiedPermission>;
  public hasPermissions: boolean;
  public pageDescriptiveText = `Audit records are written for events ranging from authentication, authorisation, and API access. Configure how to receive these through an external system such as a SIEM.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/audit-destination/`;
  public agilicusAccess = false;
  public agilicusAuthorization = false;
  private agilicusDestinations: Array<AuditDestination> = [];
  private basePathLocation = '/v1/bulk_audit_events';
  private auditDestinations: Array<AuditDestination>;
  private destinationTypes = ['file', 'webhook'];

  constructor(
    private changeDetector: ChangeDetectorRef,
    private store: Store<AppState>,
    private auditsService: AuditsService,
    private notificationService: NotificationService,
    private appErrorHandler: AppErrorHandler
  ) {}

  public ngOnInit(): void {
    this.basePathLocation = this.auditsService.configuration.basePath + this.basePathLocation;
    this.initializeColumnDefs();
    this.permissions$ = this.store.pipe(select(selectCanAdminAuditDestinations));
    this.getPermissionsAndData();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getPermissionsAndData(): void {
    const combinedPermissionsAndData$ = this.getCombinedPermissionsAndData$();
    combinedPermissionsAndData$.subscribe((combinedPermissionsAndDataResp) => {
      this.hasPermissions = combinedPermissionsAndDataResp?.permission?.hasPermission;
      this.auditDestinations = combinedPermissionsAndDataResp?.auditDestinations;
      if (!this.hasPermissions || !this.auditDestinations) {
        // Need this in order for the "No Permissions" text to be displayed when the page first loads.
        this.changeDetector.detectChanges();
        return;
      }
      this.setInitialData(this.auditDestinations);
      this.updateTable(this.auditDestinations);
    });
  }

  private getCombinedPermissionsAndData$(): Observable<CombinedPermissionsAndAuditDestinationData> {
    return this.permissions$.pipe(
      takeUntil(this.unsubscribe$),
      concatMap((hasPermissionsResp: OrgQualifiedPermission) => {
        this.orgId = hasPermissionsResp?.orgId;
        let auditDestinations$: Observable<Array<AuditDestination> | undefined> = of(undefined);
        if (!!this.orgId && !!hasPermissionsResp?.hasPermission) {
          auditDestinations$ = this.getPermissionsAndData$();
        }
        return combineLatest([of(hasPermissionsResp), auditDestinations$]);
      }),
      map(([hasPermissionsResp, auditDestinationsResp]: [OrgQualifiedPermission, Array<AuditDestination>]) => {
        const combinedPermissionsAndAuditDestinations: CombinedPermissionsAndAuditDestinationData = {
          permission: hasPermissionsResp,
          auditDestinations: !!auditDestinationsResp ? sortArrayByMetadataCreatedDate(auditDestinationsResp) : auditDestinationsResp,
        };
        return combinedPermissionsAndAuditDestinations;
      })
    );
  }

  private getPermissionsAndData$(): Observable<Array<AuditDestination>> {
    const authAuditFilterData: ListAuditDestinationsRequestParams = {
      org_id: this.orgId,
    };
    return this.auditsService.listAuditDestinations(authAuditFilterData).pipe(
      map((resp) => {
        return resp.audit_destinations;
      }),
      catchError((e) => {
        this.notificationService.error('Failed to list audit destinations');
        return of(undefined);
      })
    );
  }

  public checkBoxChanged(): void {
    if (this.agilicusDestinations.length === 0) {
      if (this.agilicusAccess || this.agilicusAuthorization) {
        this.createDestinationRow();
      }
      return;
    }
    if (!this.agilicusAccess && !this.agilicusAuthorization) {
      this.deleteDestination();
      return;
    }
    this.updateDestinationRow();
  }

  /**
   * Delete agilicus destination
   */
  private deleteDestination(): void {
    const destinationsToDelete = this.tableData.filter(
      (destination) =>
        destination.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.agilicus_bearer &&
        destination.location === this.basePathLocation
    );
    if (destinationsToDelete.length === 0) {
      return;
    }
    this.deleteSelected(destinationsToDelete);
  }

  // change agilicus destination filter list
  private updateDestinationRow(): void {
    this.tableData.forEach((destination) => {
      if (
        destination.backingObject.spec.authentication.authentication_type ===
          AuditDestinationAuthentication.AuthenticationTypeEnum.agilicus_bearer &&
        destination.backingObject.spec.location === this.basePathLocation
      ) {
        destination.backingObject.spec.filters[0].or_list = [];
        if (this.agilicusAccess) {
          destination.backingObject.spec.filters[0].or_list.push(AuditRecordAccessType.access);
        }
        if (this.agilicusAuthorization) {
          destination.backingObject.spec.filters[0].or_list.push(AuditRecordAccessType.authorization);
        }
        this.updateExistingItem(destination);
      }
    });
  }

  // create agilicus destination
  private createDestinationRow(): void {
    const newAgilicusElement: AuditDestinationElement = this.makeEmptyTableElement();
    newAgilicusElement.backingObject.spec.destination_type = 'webhook';
    newAgilicusElement.backingObject.spec.location = this.basePathLocation;
    newAgilicusElement.backingObject.spec.name = 'admin UI';
    newAgilicusElement.backingObject.spec.filters = [
      {
        filter_type: 'subsystem',
        or_list: [],
      },
    ];
    newAgilicusElement.backingObject.spec.authentication.authentication_type =
      AuditDestinationAuthentication.AuthenticationTypeEnum.agilicus_bearer;
    newAgilicusElement.backingObject.spec.comment = 'This destination added automatically by admin UI';

    if (this.agilicusAccess) {
      newAgilicusElement.backingObject.spec.filters[0].or_list.push(AuditRecordAccessType.access);
    }
    if (this.agilicusAuthorization) {
      newAgilicusElement.backingObject.spec.filters[0].or_list.push(AuditRecordAccessType.authorization);
    }
    this.createNewItem(newAgilicusElement);
  }

  private makeEmptyTableElement(): AuditDestinationElement {
    return {
      name: '',
      destination_type: this.destinationTypes[0],
      location: '',
      enabled: true,
      org_id: this.orgId,
      filters: [],
      comment: '',
      authentication_type: AuditDestinationAuthentication.AuthenticationTypeEnum.none,
      authentication: {
        authentication_type: AuditDestinationAuthentication.AuthenticationTypeEnum.none,
      },
      backingObject: {
        spec: {
          name: '',
          destination_type: this.destinationTypes[0],
          location: '',
          enabled: true,
          org_id: this.orgId,
          filters: [],
          comment: '',
          authentication: {
            authentication_type: AuditDestinationAuthentication.AuthenticationTypeEnum.none,
          },
        },
      },
      ...getDefaultNewRowProperties(),
    };
  }

  /**
   * Set initial Access and Authz check boxes
   */
  private setInitialData(auditsResp: Array<AuditDestination>): void {
    this.agilicusDestinations = [];
    if (auditsResp.length === 0) {
      return;
    }
    auditsResp.forEach((destination) => {
      if (!destination.spec.authentication) {
        destination.spec.authentication = { authentication_type: 'none' };
      }
      if (
        destination.spec.authentication.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.agilicus_bearer &&
        destination.spec.location === this.basePathLocation
      ) {
        this.agilicusDestinations.push(destination);
      }
    });
    if (this.agilicusDestinations.length === 0 || !this.filterTypeExists(this.agilicusDestinations[0].spec.filters, 'subsystem')) {
      return;
    }
    const subsystemObj = this.agilicusDestinations[0].spec.filters.find((element) => element.filter_type === 'subsystem');
    this.agilicusAccess = subsystemObj.or_list.includes(AuditRecordAccessType.access);
    this.agilicusAuthorization = subsystemObj.or_list.includes(AuditRecordAccessType.authorization);
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getNameColumn(),
        this.getTypeColumn(),
        this.getLocationColumn(),
        this.getAuthTypeColumn(),
        this.getAccessColumn(),
        this.getAuthColumn(),
        this.getUserNameColumn(),
        this.getPasswordColumn(),
        this.getTokenColumn(),
      ],
      this.columnDefs
    );
  }

  private getNameColumn(): Column<AuditDestinationElement> {
    const nameColumn = createInputColumn('name');
    nameColumn.displayName = 'Name';
    nameColumn.requiredField = () => true;
    nameColumn.isEditable = true;
    return nameColumn;
  }

  private getTypeColumn(): Column<AuditDestinationElement> {
    const typeColumn = createSelectColumn('destination_type');
    typeColumn.displayName = 'Type';
    typeColumn.isEditable = true;
    typeColumn.allowedValues = this.destinationTypes;
    return typeColumn;
  }

  private getLocationColumn(): Column<AuditDestinationElement> {
    const locationColumn = createInputColumn('location');
    locationColumn.displayName = 'Location';
    locationColumn.isEditable = true;
    return locationColumn;
  }

  private getAuthTypeColumn(): Column<AuditDestinationElement> {
    const authTypeColumn = createSelectColumn('authentication_type');
    authTypeColumn.displayName = 'Authentication Type';
    authTypeColumn.isEditable = true;
    authTypeColumn.allowedValues = [
      AuditDestinationAuthentication.AuthenticationTypeEnum.none,
      AuditDestinationAuthentication.AuthenticationTypeEnum.http_basic,
      AuditDestinationAuthentication.AuthenticationTypeEnum.http_bearer,
    ];
    authTypeColumn.getOptionDisplayValue = (option: AuditDestinationAuthentication.AuthenticationTypeEnum) => {
      return capitalizeFirstLetter(replaceCharacterWithSpace(option, '_'));
    };
    return authTypeColumn;
  }

  private getAccessColumn(): Column<AuditDestinationElement> {
    const accessColumn = createCheckBoxColumn('access');
    accessColumn.displayName = 'Access';
    accessColumn.isEditable = true;
    accessColumn.isChecked = (element: AuditDestinationElement) => {
      return element.access;
    };
    return accessColumn;
  }

  private getAuthColumn(): Column<AuditDestinationElement> {
    const authColumn = createCheckBoxColumn('authorization');
    authColumn.displayName = 'Auth';
    authColumn.isEditable = true;
    authColumn.isChecked = (element: AuditDestinationElement) => {
      return element.authorization;
    };
    return authColumn;
  }

  private getUserNameColumn(): Column<AuditDestinationElement> {
    const userNameColumn = createInputColumn('username');
    userNameColumn.isEditable = true;
    userNameColumn.isValidEntry = (value: string, element: OptionalAuditDestinationElement): boolean => {
      if (element.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.http_basic && !value) {
        return false;
      }
      return true;
    };
    userNameColumn.requiredField = (element: OptionalAuditDestinationElement) => {
      if (element?.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.http_basic) {
        return true;
      }
      return false;
    };
    return userNameColumn;
  }

  private getPasswordColumn(): Column<AuditDestinationElement> {
    const passwordColumn = createInputColumn('password');
    passwordColumn.displayName = 'Password';
    passwordColumn.isEditable = true;
    passwordColumn.isValidEntry = (value: string, element: OptionalAuditDestinationElement): boolean => {
      if (element.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.http_basic && !value) {
        return false;
      }
      return true;
    };
    passwordColumn.requiredField = (element: OptionalAuditDestinationElement) => {
      if (element?.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.http_basic) {
        return true;
      }
      return false;
    };
    return passwordColumn;
  }

  private getTokenColumn(): Column<AuditDestinationElement> {
    const tokenColumn = createInputColumn('token');
    tokenColumn.displayName = 'Token';
    tokenColumn.isEditable = true;
    tokenColumn.isValidEntry = (value: string, element: OptionalAuditDestinationElement): boolean => {
      if (element.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.http_bearer && !value) {
        return false;
      }
      return true;
    };
    tokenColumn.requiredField = (element: OptionalAuditDestinationElement) => {
      if (element?.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.http_bearer) {
        return true;
      }
      return false;
    };
    return tokenColumn;
  }

  public getAuthAuditFilterData(): ListAuditDestinationsRequestParams {
    const authAuditFilterData: ListAuditDestinationsRequestParams = {
      org_id: this.orgId,
    };
    return authAuditFilterData;
  }

  private buildData(data: Array<AuditDestination>): void {
    const dataForTable: Array<AuditDestinationElement> = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      dataForTable.push(this.createTableElement(item, i));
    }
    updateTableElements(this.tableData, dataForTable);
  }

  private createTableElement(auditDestination: AuditDestination, index: number): AuditDestinationElement {
    const data: AuditDestinationElement = {
      name: auditDestination.spec?.name,
      destination_type: auditDestination.spec?.destination_type,
      location: auditDestination.spec?.location,
      enabled: auditDestination.spec?.enabled,
      org_id: auditDestination.spec?.org_id,
      filters: auditDestination.spec?.filters,
      comment: auditDestination.spec?.comment,
      authentication_type: auditDestination.spec?.authentication?.authentication_type,
      username: auditDestination.spec?.authentication?.http_basic?.username,
      password: auditDestination.spec?.authentication?.http_basic?.password,
      token: auditDestination.spec?.authentication?.http_bearer?.token,
      backingObject: auditDestination,
      access: false,
      authorization: false,
      ...getDefaultTableProperties(index),
    };
    if (auditDestination.spec.filters.length > 0) {
      const filtersList = auditDestination.spec.filters[0].or_list;
      data.access = filtersList.includes(AuditRecordAccessType.access) ? true : false;
      data.authorization = filtersList.includes(AuditRecordAccessType.authorization) ? true : false;
    }
    if (
      data.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.agilicus_bearer &&
      data.location === this.basePathLocation
    ) {
      data.showRow = false;
    }
    return data;
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private updateTable(data: Array<AuditDestination>): void {
    this.buildData(data);
    this.replaceTableWithCopy();
  }

  /**
   * Receives an element from the table then updates and saves
   * the data.
   */
  public updateEvent(updatedElement: AuditDestinationElement): void {
    this.saveItem(updatedElement);
  }

  private saveItem(tableElement: AuditDestinationElement): void {
    if (tableElement.index === -1) {
      this.createNewItem(tableElement);
    } else {
      this.updateExistingItem(tableElement);
    }
  }

  private getItemFromTableElement(tableElement: AuditDestinationElement): AuditDestination {
    let result: AuditDestination = tableElement.backingObject;
    if (
      result.spec.authentication.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.agilicus_bearer &&
      result.spec.location === this.basePathLocation
    ) {
      return result;
    }
    result.spec.name = tableElement.name.trim();
    result.spec.destination_type = tableElement.destination_type;
    result.spec.location = tableElement.location.trim();
    result.spec.authentication.authentication_type = tableElement.authentication_type;
    result.spec.enabled = tableElement.enabled;
    result.spec.org_id = tableElement.org_id;
    result.spec.filters = tableElement.filters;
    result.spec.comment = tableElement.comment;
    if (!!result.spec.authentication.http_basic) {
      result.spec.authentication.http_basic.username = tableElement?.username ? tableElement?.username.trim() : '';
      result.spec.authentication.http_basic.password = tableElement?.password ? tableElement?.password : '';
    }
    if (!!result.spec.authentication.http_bearer) {
      result.spec.authentication.http_bearer.token = tableElement?.token ? tableElement?.token.trim() : '';
    }
    if ((tableElement.username || tableElement.password) && !result.spec.authentication.http_basic) {
      result.spec.authentication.http_basic = {
        username: tableElement?.username ? tableElement?.username.trim() : '',
        password: tableElement?.password ? tableElement?.password : '',
      };
    }
    if (tableElement.token && !result.spec.authentication.http_bearer) {
      result.spec.authentication.http_bearer = { token: tableElement?.token ? tableElement?.token.trim() : '' };
    }
    result = this.setAuditDestinationFilters(result, tableElement);
    return result;
  }

  private setAuditDestinationFilters(result: AuditDestination, tableElement: AuditDestinationElement): AuditDestination {
    let subsystemObj: AuditDestinationFilter;
    if (result.spec.filters.length > 0 && this.filterTypeExists(result.spec.filters, 'subsystem')) {
      subsystemObj = result.spec.filters.find((element) => element.filter_type === 'subsystem');
      subsystemObj.or_list = [];
    } else {
      result.spec.filters = [
        {
          filter_type: 'subsystem',
          or_list: [],
        },
      ];
      subsystemObj = result.spec.filters[0];
    }
    if (tableElement.access) {
      subsystemObj.or_list.push(AuditRecordAccessType.access);
    }
    if (tableElement.authorization) {
      subsystemObj.or_list.push(AuditRecordAccessType.authorization);
    }
    return result;
  }

  private filterTypeExists(arr: Array<AuditDestinationFilter>, value: string): boolean {
    return arr.some((el) => {
      return el.filter_type === value;
    });
  }

  private postItem(itemToCreate: AuditDestination): Observable<AuditDestination> {
    const createRequestParams: CreateAuditDestinationRequestParams = {
      AuditDestination: itemToCreate,
    };
    return this.auditsService.createAuditDestination(createRequestParams);
  }

  private createNewItem(newTableElement: AuditDestinationElement): void {
    const newItem = this.getItemFromTableElement(newTableElement);
    this.postItem(newItem).subscribe(
      (postResp) => {
        this.notificationService.success(`Destination "${postResp.spec.name}" was successfully created`);
      },
      (errorResp) => {
        const baseMessage = `Failed to create Destination "${newTableElement.backingObject.spec.name}"`;
        this.appErrorHandler.handlePotentialConflict(errorResp, baseMessage, 'reload');
      },
      () => {
        this.getPermissionsAndData();
      }
    );
  }

  private updateExistingItem(updatedTableElement: AuditDestinationElement): void {
    const updatedItem = this.getItemFromTableElement(updatedTableElement);
    this.putItem(updatedItem).subscribe(
      (putResp) => {
        updatedTableElement.backingObject = putResp;
        this.notificationService.success(`Destination "${putResp.spec.name}" was successfully updated`);
      },
      (errorResp) => {
        const baseMessage = `Failed to update Destination "${updatedTableElement.backingObject.spec.name}"`;
        this.appErrorHandler.handlePotentialConflict(errorResp, baseMessage, 'reload');
      },
      () => {
        this.getPermissionsAndData();
      }
    );
  }

  private putItem(itemToUpdate: AuditDestination): Observable<AuditDestination> {
    const getter = (item: AuditDestination) => {
      const getRequestParams: GetAuditDestinationRequestParams = {
        destination_id: item.metadata.id,
        org_id: this.orgId,
      };
      return this.auditsService.getAuditDestination(getRequestParams);
    };
    const putter = (item: AuditDestination) => {
      const replaceRequestParams: ReplaceAuditDestinationRequestParams = {
        destination_id: item.metadata.id,
        AuditDestination: item,
      };
      return this.auditsService.replaceAuditDestination(replaceRequestParams);
    };
    return patch_via_put(itemToUpdate, getter, putter);
  }

  public deleteSelected(itemsToDelete: Array<AuditDestinationElement>): void {
    const observablesArray: Array<Observable<object>> = [];
    for (const item of itemsToDelete) {
      if (item.index === -1) {
        continue;
      }
      observablesArray.push(
        this.auditsService.deleteAuditDestination({
          destination_id: item.backingObject.metadata.id,
          org_id: this.orgId,
        })
      );
    }
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('Destinations were successfully deleted');
        },
        (errorResp) => {
          this.notificationService.error('Failed to delete all selected destinations');
        },
        () => {
          this.getPermissionsAndData();
        }
      );
  }

  /**
   * Triggered when a user selects a new option from the dropdown menu
   * in the table. The data is sent from the table-layout to this component.
   */
  public updateSelection(params: { value: string; column: Column<AuditDestinationElement>; element: AuditDestinationElement }): void {
    if (params.column.name !== 'authentication_type' && params.column.name !== 'destination_type') {
      return;
    }

    if (params.column.name === 'authentication_type') {
      const isAuthenticationTypeEnum = createEnumChecker(AuditDestinationAuthentication.AuthenticationTypeEnum);
      if (isAuthenticationTypeEnum(params.value)) {
        params.element.authentication_type = params.value;
      }
    } else if (params.column.name === 'destination_type') {
      params.element.destination_type = params.value;
    }
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this), ['destination_type', 'authentication_type']);
  }
}
